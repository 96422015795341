import React from 'react'

const Layout = ({children, pageTitle, ...props}) => {
    return (
        <>
            <section className="layout">
                <div className="content">{children}</div>
            </section>
        </>
    )
}

export default Layout;