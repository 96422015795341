import React, {useEffect, useState} from 'react'
import useFetch from '../../utils/useFetch'
import { ArticleComp, ImgComp, ListComputers } from './MicrocomputersElements'
const url = '/api/microcomputers'

const MicroComputers = () => {
    const { data, error, isPending }  = useFetch(url)
    return (
        <>
          <ListComputers>
          <h1>Microcomputers</h1>

          {data.map((comp) => {
              const { id, url, price, name} = comp
              return (
                  <ArticleComp key={id}>
                    <ImgComp src={url} alt={name}/>
                    <footer>{name}</footer>
                  </ArticleComp>
              )
          })}  
          </ListComputers>
        </>
    )
}


export default MicroComputers
