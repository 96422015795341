import React from 'react'
//import VM1 from '../components/VM/VM1'
//import VM2 from '../components/VM/VM2'
//import VM3 from '../components/VM/VM3'
import { HomeWrapper } from './Home/HomeElements'


function VMachine() {
    return (
        <HomeWrapper id="homewrapper">
        </HomeWrapper>
    )
}

export default VMachine
