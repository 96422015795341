import React, {useState} from 'react'
import * as AiIcons from 'react-icons/ai'
import { Nav, NavLink, Bars, NavMenu, NavBtn, NavBtnLink } from './NavbarElements'
import { NavIcon, NavLogo, SidebarNav, SidebarWrap } from './SidebarElements'
import { SidebarData } from './SidebarData'
import SubMenu from './SubMenu'
import { IconContext } from 'react-icons/lib'
import { FaBars } from 'react-icons/fa'
import logoR from '../../assets/images/logorr.png'

const Navbar = () => {
    const [sidebar, setSidebar] = useState(false);
    const showSidebar = () => setSidebar(!sidebar);

    return (
        <>
        <IconContext.Provider value={{color: '#fcfcfc'}}>
            <Nav>
                <NavBtnLink to="/">
                    <img src={logoR} height="80px" alt="RR"/>
                </NavBtnLink>
                <SidebarNav sidebar={sidebar}>
                    <SidebarWrap>
                        <NavIcon to="#">
                            <AiIcons.AiOutlineClose onClick={showSidebar}/>
                        </NavIcon>
                        {SidebarData.map((item, index) => {
                            return <SubMenu item={item} key={index} />
                        })}
                    </SidebarWrap>
                </SidebarNav>
                <NavMenu>
                    <NavLink to="/games" >
                        Juegos
                    </NavLink>
                    <NavLink to="/canvas" >
                        Labs
                    </NavLink>
                    <NavLink to="/retro" >
                        Retro
                    </NavLink>
                    <NavLink to="/music" >
                        Music
                    </NavLink>
                    <NavLink to="/board" >
                        Board
                    </NavLink>
                </NavMenu>
                <NavLogo to="#">
                    <div onClick={showSidebar}>
                        <FaBars/>
                    </div>
                </NavLogo>
            </Nav>
        </IconContext.Provider>
        </>
    )
}

export default Navbar
