import React, {useEffect, useState} from 'react'
import Canvas from '../Canvas'

const Lab3 = () => {
    const [canvasWidth, setCanvasWidth] = useState(1670)
    const [canvasHeight, setCanvasHeight] = useState(889)

    let particleArray = []
    const colors = [
        'white',
        'rgba(255,255,255,0.3)',
        'rgba(173,216,230,0.8)',
        'rgba(211,211,211,0.8)',
    ]
    const maxSize = 40;
    const minSize = 0;
    const mouseRadius = 60;
    const constante = 1

    let mouse = {
        x: null,
        y: null
    };

    window.addEventListener('mousemove',function(event){
        mouse.x = event.layerX
        mouse.y = event.layerY
    })

    useEffect(() => {
        for(let i=0;i<1000;i++){
            let size = 0
            
            let x = (Math.random() * ((canvasWidth - size * constante) - (size * constante)) + size * constante);
            let y = (Math.random() * ((canvasHeight - size * constante) - (size * constante)) + size * constante);
            let directionX = (Math.random() * .2) - .1;
            let directionY = (Math.random() * .2) - .1;
            let color = colors[Math.floor(Math.random() * colors.length)]
            particleArray.push(new Particle(x,y,directionX, directionY, size, color))
        }
    }, [])

    function Particle(x,y, directionX, directionY, size, color){
        this.x = x
        this.y = y
        this.directionX = directionX
        this.directionY = directionY
        this.size = size
        this.color = color
    }
    Particle.prototype.draw = function(ctx){
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2, false);
        ctx.fillStyle = this.color
        ctx.fill()
    }
    Particle.prototype.update = function(ctx){
        if(this.x + this.size*constante > canvasWidth || this.x - this.size*constante < 0){
            this.directionX = -this.directionX
        }
        if(this.y + this.size*constante > canvasHeight || this.y - this.size*constante < 0){
            this.directionY = -this.directionY
        }
        this.x += this.directionX;
        this.y += this.directionY;

        // Raton
        if(    mouse.x - this.x < mouseRadius 
            && mouse.x - this.x > -mouseRadius
            && mouse.y - this.y < mouseRadius
            && mouse.y - this.y > -mouseRadius){
                if(this.size < maxSize){
                    this.size += 3;
                }
            }  else if(this.size > minSize){
                this.size -= 0.1
            }
            if(this.size < 0){
                this.size = 0
            }
        this.draw(ctx)
    }    

    const draw = (ctx, frameCount) => {
        ctx.clearRect(0, 0, canvasWidth, canvasHeight)
        for(let i=0;i<particleArray.length;i++){
            particleArray[i].update(ctx);
        }
    }

    return (
        <Canvas draw={draw} background='linear-gradient(#25364f,#4d71a5,#9bc4ff)'/>
    )
}

export default Lab3
