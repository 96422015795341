import React, { useState } from 'react'
import { CalcElem, CalcHeader, ClearButton, RoundButton } from './CalculatorElements'
import Keyboard from './Keyboard'
import Screen from './Screen'

function Calculator() {
    const [input, setInput] = useState('')

    // Aggregar entrada
    const addInput = (char) => {
        var newInput = ""
        if(input.length === 0){
            if(isNaN(Number(char))){
                newInput = [...input];
                newInput += 0 + char
                setInput(newInput)
                return
            }else{
                newInput = [...input];
                newInput += char
                setInput(newInput)
            }
        }
        newInput = input
        newInput += char
        setInput(newInput)
        
    }

    // Limpiar entrada
    const clearInput = () => {
        setInput('')
    }

    // Calcular
    const calculate = () => {
        try{
            setInput(evaluate(input))
        }catch(e){
            console.log(e)
        }
    }

    function evaluate(fn){
        return new Function('return ' + fn)();
    }

    return (
        <CalcElem>
            <CalcHeader>Calculadora</CalcHeader>
            <Screen input={input}/>
            <Keyboard addInput={addInput} calculate = {calculate} clearInput={clearInput}/>
        </CalcElem>
    )
}

export default Calculator
