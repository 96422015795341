import React, {useState, useEffect} from 'react'
import Canvas from '../../Canvas'
import { DivBoard } from '../GamesElements'

const Tetris = () => {

    const [canvasWidth, setCanvasWidth] = useState(900)
    const [canvasHeight, setCanvasHeight] = useState(600)
    const [context, setContext] = useState(null)
    const [score, setScore] = useState(0)
    const tileSize = 20

    document.addEventListener('keydown', event => {
        if(event.key === "ArrowLeft"){
            playerMove(-1)
        }else if(event.key === "ArrowRight"){
            playerMove(1)
        }else if(event.key === "ArrowDown"){
            playerDrop();
        }else if(event.key === "q" || event.key === "ArrowUp"){
            playerRotate(-1)
        }
        else if(event.key === "a"){
            playerRotate(1)
        }


    })

    const mouseMove = (e) =>{
    }
    const mouseLeave = (e) => {
    }

    const mouseClick = (e) =>{
    }

    const arenaSweep = () => {
        let rowCount = 1;
        outer: for(let y = arena.length - 1; y > 0;--y){
            for(let x = 0; x < arena[y].length;++x){
                if(arena[y][x] === 0){
                    continue outer;
                }
            }
            const row = arena.splice(y, 1)[0].fill(0)
            arena.unshift(row)
            ++y;
            player.score += rowCount * 10
            rowCount *= 2
        }
    }

    const collide = (arena, player) => {
        const [m ,o] = [player.matrix, player.pos]
        for(let y = 0;y<m.length;++y){
            for(let x = 0;x<m[y].length;++x){
                if(m[y][x] !== 0 && (arena[y + o.y] && arena[y+o.y][x+o.x]) !== 0){
                    return true;
                }
            }
        }
        return false;
    }

    const createMatrix = (w,h) => {
        const matrix = []
        while(h--){
            matrix.push(new Array(w).fill(0));
        }
        return matrix
    }

    const createPiece = (type) => {
        if(type === 'T'){
            return [
                [0,0,0],
                [1,1,1],
                [0,1,0]
            ]
        }else if(type === 'O'){
            return [
                [2,2],
                [2,2]
            ] 
        }else if(type === 'L'){
            return [
                [0,3,0],
                [0,3,0],
                [0,3,3]
            ]
        }else if(type === 'J'){
            return [
                [0,4,0],
                [0,4,0],
                [4,4,0]
            ]
        }
        else if(type === 'I'){
            return [
                [0,5,0,0],
                [0,5,0,0],
                [0,5,0,0],
                [0,5,0,0]
            ]
        }
        else if(type === 'S'){
            return [
                [0,6,6],
                [6,6,0],
                [0,0,0]
            ]
        }
        else if(type === 'Z'){
            return [
                [7,7,0],
                [0,7,7],
                [0,0,0]
            ]
        }
    }

    const drawMatrix = (matrix, offset) => {
        matrix.forEach((row, y) => {
            row.forEach((value,x) => {
                if(value !== 0){
                    context.fillStyle = colors[value]
                    context.fillRect((x+offset.x)*tileSize,(y+offset.y)*tileSize,tileSize,tileSize)
                }
            })
        })
    }

    function merge(arena, player){
        player.matrix.forEach((row,y) => {
            row.forEach((value, x) => {
                if(value !== 0){
                    arena[y + player.pos.y][x + player.pos.x] = value;
                }
            })
        })
    }

    const playerMove = (dir) => {
        player.pos.x += dir
        if(collide(arena, player)){
            player.pos.x -= dir
        }
    }

    const playerDrop = () => {
        player.pos.y++;
        if(collide(arena, player)){
            player.pos.y--;
            merge(arena, player);
            playerReset()
            arenaSweep()
        }
        dropCounter = 0;
    }

    const playerReset = () => {
        const pieces = 'ILJOTSZ'
        player.matrix = createPiece(pieces[pieces.length * Math.random() | 0])
        player.pos.y = 0;
        player.pos.x = (arena[0].length / 2 | 0) - (player.matrix[0].length/ 2 | 0)
        if(collide(arena, player)){
            arena.forEach(row => row.fill(0));
            player.score = 0
        }
    }

    const colors = [
        null,
        'red',
        'blue',
        'violet',
        'green',
        'purple',
        'orange',
        'pink'
    ]

    const arena = createMatrix(15,30)

    const player = {
        pos: {x: 0, y:0},
        matrix: createPiece('I'),
        score: 10
    }
    //const player = playerReset()

    const draw = () => { 
        context.fillStyle = '#000'
        context.fillRect(0,0,canvasWidth, canvasHeight)

        drawMatrix(arena, {x: 0, y: 0})
        drawMatrix(player.matrix, player.pos)
    }

    const playerRotate = (dir) => {
        const pos = player.pos.x
        let offset = 1
        rotate(player.matrix, dir)
        while(collide(arena, player)){
            player.pos.x += offset
            offset = -(offset + (offset > 0 ? 1 : -1));
            if(offset > player.matrix[0].length){
                rotate(player.matrix, -dir);
                player.pos.x = pos
                return;
            }
        }
    }

    const rotate = (matrix, dir) => {
        for(let y=0;y<matrix.length;++y){
            for(let x = 0;x < y;++x){
                [
                    matrix[x][y],
                    matrix[y][x]
                ] = 
                [
                    matrix[y][x],
                    matrix[x][y]
                ]
            }
        }
        if(dir > 0){
            matrix.forEach(row => row.reverse());
        }else{
            matrix.reverse();
        }
    }

    let dropCounter = 0
    let dropInterval = 1000;
    let lastTime = 0;

    const update = (ctx, time, width, height) => {
        setCanvasWidth(width)
        setCanvasHeight(height)
        setContext(ctx)

        const deltaTime = time - lastTime;
        lastTime = time;

        if(context)
        {
            dropCounter += deltaTime
            if(dropCounter > dropInterval){
                playerDrop();
            }
            draw()
        }
    }


    return (
        <>
            <div>Score: {player.score}</div>
            <DivBoard>
                <Canvas draw={update} width={'300px'} height={'600px'} background={'#fff'} onMouseMove={mouseMove} onMouseLeave={mouseLeave} onClick={mouseClick}/>
            </DivBoard>
        </>
    )
}

export default Tetris
