import React from 'react'
import { useParams } from 'react-router-dom'
import ReactHlsPlayer from 'react-hls-player/dist'
import { DivPageFlex } from './PagesElements'
import useFetch from '../utils/useFetch'

const Video = () => {
    let {id} = useParams()
    const url = id ? `/api/video/?id=${id}` : '/api/video/'
    const { data, error, isPending }  = useFetch(url)

    console.log('id', id)
    // "https://cdnlt5.tokyvideo.com/videos/560/56054/15f09f11e093b7008cd1bf0b96c5664c7f981926343701d6eff279ddeb2f8cb5.m3u8" 
    return (
        <DivPageFlex>
            {data && data.data &&
            <ReactHlsPlayer
                src= {data.data.src}
                autoPlay={false}
                width="100%"
                controls={true}/>
            }
        </DivPageFlex>
    )
}

export default Video
