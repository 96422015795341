import React, { useState, useEffect } from 'react'
import Canvas from '../../Canvas'
import {DivCanvas, EmuTitle, EmuMain, EmuSelectGame} from '../EmuElements'

function Chip8() {

    const draw = (ctx, frameCount, width, height) => {
		ctx.clearRect(0,0,width, height)
	}

    const loadRom = async (event) => {
        const rom = event.target.value
        const response = await fetch(`./roms/${rom}`)
        const arrayBuffer = await response.arrayBuffer()
        console.log('Response', arrayBuffer)
    }
    
    return (
        <EmuMain>
            <EmuTitle>CHIP8</EmuTitle>
            <DivCanvas width="640px" height="320px">
                <Canvas draw={draw} width="640px" height="320px" background={'#000'}/>
            </DivCanvas>
            <aside>
                <EmuSelectGame onChange={loadRom}>
                    <option disabled selected>Cargar ROM...</option>
                    <option value="CONNECT4">Connect4</option>
                    <option value="PONG">Pong</option>
                    <option value="INVADERS">Invaders</option>
                    <option value="TETRIS">Tetris</option>
                    <option value="WALL">Wall</option>
                    <option value="LANDING">Lunar Landing</option>
                    <option value="BLINKY">Blinky</option>
                    <option value="MAZE">Maze</option>
                    <option value="TANK">Tank</option>
                    <option value="TRON">Tron</option>
                </EmuSelectGame>
            </aside>
        </EmuMain>
    )
}

export default Chip8