import styled from 'styled-components'

export const Lab8Button = styled.button`
    cursor: pointer;
    outline: none;
    border: none;
    position: absolute;
    left: 0;
    bottom: 5%;
    z-index: 10;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 20px 30px;
    border-radius: 50px;
    background: ${props => props.background || 'blue'};
    color: yellow;
    font-size: 20px;
    overflow: hidden;
`

export const ContainerLab11 = styled.div`
`

export const ContentLab11 = styled.div`
`
