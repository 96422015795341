import React from 'react'
import * as FaIcons from 'react-icons/fa'
import { DivModalAddPlayList } from './MusicElements'

const ModalAddPlaylist = ({children, show, close, toast}) => {
    if(!show) return null;
    return (
        <>
        <DivModalAddPlayList>
            <div className="modal-content">
                <FaIcons.FaTimes onClick={close}/>
                {children}
            </div>            
        </DivModalAddPlayList>        
        </>
    )
}

export default ModalAddPlaylist
