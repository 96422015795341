import styled from 'styled-components'

export const ListComputers = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    
`

export const ArticleComp = styled.div`
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    width: 300px;
    height: 300px;
    box-shadow: 5px 5px 20px gray;
    margin: 10px;
    align-items: center;
`

export const ImgComp = styled.img`
    margin-top: 25px;
    width: 250px;
    border-radius: 15px;
`

