import React from 'react'
import BoardView from './comps/BoardView'
import './main.scss'
import './styles.scss'


function G2048() {
    return (
        <>
            <BoardView/>
        </>
    )
}

export default G2048
