import React, {useEffect} from 'react'
import { DivToast } from './MusicElements'

const Toast = ({toast, close}) => {
    useEffect(() => {
        if(!toast) return

        const closeToast = () => {
            setTimeout(() => {
                close()
            }, 1500)
        }
        closeToast()

        return () => {
            clearTimeout(closeToast)
        }
    }, [toast])

    if(!toast) return null

    return (
        <DivToast>
            {toast}
        </DivToast>
    )
}

export default Toast
