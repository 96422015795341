import Phaser from 'phaser'
import React from 'react'

var spacefield;
var backgroundV

var player;
var cursors

var bullets
var bulletTime = 0
var fireButton

const mainState = {
    key: 'mainState',
    active: true,
    preload: function(){
        this.load.image('starfield','/assets/platform/starfield.png')
        this.load.image('player','/assets/platform/P-red-a.png')
        this.load.image('bullet','/assets/platform/bullet.png')
    },
    create: function(){
        spacefield = this.add.tileSprite(0,0,800,600,'starfield')
        spacefield.setOrigin(0,0)
        backgroundV = 5
        console.log(this)
        player = this.physics.add.sprite(this.cameras.main.centerX, this.cameras.main.centerY, 'player')
        player.setScale(0.1)

        cursors = this.input.keyboard.createCursorKeys()

        bullets = this.add.group()
        bullets.enableBody = true
        bullets.physicsBodyType = Phaser.Physics.ARCADE
        bullets.createMultiple(30, 'bullet')        
        console.log(bullets)
        /*bullets.setAll('anchorX', 0.5)
        bullets.setAll('anchorY', 1)
        bullets.setAll('outOfBoundsKill', true)
        bullets.setAll('checkWorldBounds', true)*/

        fireButton = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.SPACE)
        console.log(fireButton, bullets)
    },
    update: function(){
        player.body.velocity.x = 0
        player.body.velocity.y = 0
        spacefield.tilePositionY -= backgroundV;

        if(cursors.left.isDown){
            player.body.velocity.x  = -350
        }
        if(cursors.right.isDown){
            player.body.velocity.x  = 350
        }
        if(cursors.up.isDown){
            player.body.velocity.y  = -350
        }
        if(cursors.down.isDown){
            player.body.velocity.y  = 350
        }

        if(fireButton.isDown){
            this.fireBullet()
        }
    },
    fireBullet: () => {
        if(this.time.now > bulletTime){
            var bullet = bullets.get()
            if(bullet){
                bullet.reset(player.x + 14, player.y)
                bullet.body.velocity.y = -400
                bulletTime = this.time.now + 200
            }
        }
    }

}
export default mainState
