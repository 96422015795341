import Phaser from 'phaser'
import { IonPhaser } from '@ion-phaser/react'
import mainState from './mainState'
import { DivBoard } from '../GamesElements'

const Platform = () => {

    const game = {
        type: Phaser.AUTO,
        width: 800,
        height: 600,
        backgroundColor: "#472d3c",
        pixelArt: true,
        zoom: 1,
        scene: [mainState],
        physics: {
            default: "arcade",
            arcade: {
                gravity: {y:0}
            }
        }
    }

    return (
        <DivBoard>
            <IonPhaser game={game} initialize={true} />
        </DivBoard>
    )
}

export default Platform
