import * as AiIcons from 'react-icons/ai'
import * as IoIcons from 'react-icons/io'
import * as RiIcons from 'react-icons/ri'
import * as GiIcons from 'react-icons/gi'

export const SidebarData = [
    {
        title: 'Juegos',
        path: '/games',
        icon: <AiIcons.AiFillHome/>,
        iconClosed: <RiIcons.RiArrowDownSFill/>,
        iconOpened: <RiIcons.RiArrowUpSFill/>,
        subNav: [
            {
                title: 'Tetris',
                path: '/games/tetris',
                icon: <GiIcons.GiCube/>
            },
            {
                title: '2048',
                path: '/games/2048',
                icon: <RiIcons.RiNumbersFill/>
            },
            {
                title: 'NanoDungeon',
                path: '/games/nanodungeon',
                icon: <RiIcons.RiNumbersFill/>
            }
        ]
    },
    {
        title: 'Labs',
        path: '/labs',
        icon: <AiIcons.AiFillHome/>,
        iconClosed: <RiIcons.RiArrowDownSFill/>,
        iconOpened: <RiIcons.RiArrowUpSFill/>,
        subNav: [
            {
                title: 'Lab 1',
                path: '/labs/1',
                icon: <IoIcons.IoIosPaper/>
            },
            {
                title: 'Lab 2',
                path: '/labs/2',
                icon: <IoIcons.IoIosPaper/>
            }
        ]
    },
    {
        title: 'Retro',
        path: '/retro',
        icon: <IoIcons.IoIosPaper/>
    },
    {
        title: 'Music',
        path: '/music',
        icon: <IoIcons.IoIosMusicalNote/>
    },
    {
        title: 'Board',
        path: '/board',
        icon: <AiIcons.AiFillHome/>,
        iconClosed: <RiIcons.RiArrowDownSFill/>,
        iconOpened: <RiIcons.RiArrowUpSFill/>,
        subNav: [
            {
                title: 'Board 1',
                path: '/reports/reports1',
                icon: <IoIcons.IoIosPaper/>
            },
            {
                title: 'Reports2',
                path: '/reports/reports1',
                icon: <IoIcons.IoIosPaper/>
            },
            {
                title: 'Reports23',
                path: '/reports/reports3',
                icon: <IoIcons.IoIosPaper/>
            }
        ]
    },
    {
        title: 'Serverless',
        icon: <GiIcons.Gi3DStairs/>,
        iconClosed: <RiIcons.RiArrowDownSFill/>,
        iconOpened: <RiIcons.RiArrowUpSFill/>,
        subNav: [
            {
                title: 'Ex 1',
                path: '/serverless/ex1',
                icon: <GiIcons.GiAbstract042/>
            },
            {
                title: 'Ex 2',
                path: '/serverless/ex2',
                icon: <GiIcons.GiAbstract042/>
            },
            {
                title: 'Ex 3',
                path: '/serverless/ex3',
                icon: <GiIcons.GiAbstract042/>
            }
        ]
    },
    {
        title: 'Canvas Labs',
        path: '/canvas',
        icon: <IoIcons.IoIosPaper/>
    },
    {
        title: 'Emuladores',
        path: '/emulators',
        icon: <IoIcons.IoIosPaper/>,
        iconClosed: <RiIcons.RiArrowDownSFill/>,
        iconOpened: <RiIcons.RiArrowUpSFill/>,
        subNav: [
            {
                title: 'Chip 8',
                path: '/emu/chip8',
                icon: <GiIcons.GiAbstract042/>
            }
        ]
        
    },
    {
        title: 'Chat',
        path: '/chat',
        icon: <IoIcons.IoIosPaper/>
        
    },
    {
        title: 'Calculadora',
        path: '/calc',
        icon: <IoIcons.IoIosCalculator/>
        
    },
]