import { useRef, useEffect } from 'react'

const useCanvas = (draw, options={}) => {
    const canvasRef = useRef(null)

    function resizeCanvasToDisplaySize(canvas) {
    
        const { width, height } = canvas.getBoundingClientRect()
    
        if (canvas.width !== width || canvas.height !== height) {
          canvas.width = width
          canvas.height = height
          return true // here you can return some usefull information like delta width and delta height instead of just true
          // this information can be used in the next redraw...
        }
    
        return false
      }

    useEffect(() => {
        const canvas = canvasRef.current
        const context = canvas.getContext('2d')
        let width = getComputedStyle(canvas).getPropertyValue('width').slice(0, -2);
        let height = getComputedStyle(canvas).getPropertyValue('height').slice(0, -2);
        canvas.width = width
        canvas.height= height
        context.globalCompositeOperation = (options.globalCompositeOperation || 'source-over')
        let frameCount = 0
        let animationFrameId

        const render = (time=0) => {
            frameCount = time
            //resizeCanvasToDisplaySize(canvas)
            draw(context, frameCount, width, height)
            animationFrameId = window.requestAnimationFrame(render)
        }
        render()

        return () => {
            window.cancelAnimationFrame(animationFrameId)
        }
    }, [draw])

    return canvasRef
}

export default useCanvas