import React, {useEffect, useState} from 'react'
import Canvas from '../Canvas'
import { Lab8Button } from './LabsElements'

const Lab10 = () => {
    const [canvasWidth, setCanvasWidth] = useState(1670)
    const [canvasHeight, setCanvasHeight] = useState(889)
    const [context, setContext] = useState(null)
    const maxLevel = 6;
    const branches = 3
    let sides = Math.floor((Math.random()* 10) + 3)
    let spread = (Math.random() * 48) + 0.51
    let angle = Math.PI * 2 * spread    

    useEffect(() => {
        if(context){
            context.translate(canvasWidth/2, canvasHeight/2)
            drawSnowflake()
        }
    }, [context, canvasWidth, canvasHeight])

    const generarCopo = () => {
        sides = Math.floor((Math.random()* 10) + 3)
        spread = (Math.random() * 48) + 0.51
        angle = Math.PI * 2 * spread
        context.clearRect(-canvasWidth, -canvasHeight, canvasWidth *2, canvasHeight*2)
        drawSnowflake()
    }

    const drawSnowflake =  () =>{
        for(let i=0;i<sides;i++){
            drawLine(0)
            context.rotate(Math.PI * 2 / sides)
        }
    }

    const drawLine = (level) => {
        if(level > maxLevel) return;

        context.strokeStyle = '#fff'
        context.lineWidth = 2;
        context.beginPath()
        context.moveTo(0,0)
        context.lineTo(200,0)
        context.stroke()

        for(let i=1;i<branches + 1; i++){
            context.save()
            context.translate(200* i / (branches+1), 0)
            context.scale(0.5,0.5)
            context.save()

            context.rotate(angle)
            drawLine(level + 1)
            context.restore()
            context.save()

            context.rotate(-angle)
            drawLine(level + 2)
            context.restore()

            context.restore()
        }
    }

    const draw = (ctx, frameCount, width, height) => {
        setCanvasWidth(width)
        setCanvasHeight(height)
        setContext(ctx)
    }

    return (
        <>
        <Canvas draw={draw} background='#000' />
        <Lab8Button onClick={generarCopo}>Generar Copo</Lab8Button>
        </>
    )
}

export default Lab10

