import styled from 'styled-components'

export const DivCanvas = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${props => props.width || '900px'};
    height: ${props => props.height || '600px'};
`

export const DivBoard = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-family: "Clear Sans", sans-serif;
    font-size: 21px;
    padding-top: 16px;
`

export const ListGames = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;    
`

export const ArticleComp = styled.div`
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    width: 300px;
    height: 300px;
    box-shadow: 5px 5px 20px gray;
    margin: 30px;
    align-items: center;
`

export const ImgComp = styled.img`
    margin-top: 25px;
    width: 250px;
    border-radius: 15px;
`

