import React, {useEffect, useState} from 'react'
import Canvas from '../Canvas'
import { Lab8Button } from './LabsElements'

const Lab9 = () => {
    const [canvasWidth, setCanvasWidth] = useState(1670)
    const [canvasHeight, setCanvasHeight] = useState(889)
    const [context, setContext] = useState(null)
    const [btnColor, setBtnColor] = useState(null)
    let curve = 0
    let curve2 = 50

    let mouse = {
        x: undefined,
        y: undefined,
        radius: 100
    };

    window.addEventListener('mousemove',function(event){
        mouse.x = event.layerX
        mouse.y = event.layerY
    })

    window.addEventListener('mouseout', function(){
        mouse.x = undefined
        mouse.y = undefined
    })

    function drawTree(startX, startY, len, angle, branchWidth, color1, color2){
        context.beginPath();
        context.save();
        context.strokeStyle = color1;
        context.fillStyle = color2;
        context.shadowBlur = 5;
        context.shadowColor = 'rgba(255,255,255,.5)';
        context.lineWidth = branchWidth;
        context.translate(startX, startY)
        context.rotate(angle * Math.PI/180)
        context.moveTo(0,0)
        //context.lineTo(0, -len)
        if(angle > 10)
            context.bezierCurveTo(curve2, -len/2,  curve2, -len/2, 0, -len)
        else
            context.bezierCurveTo(curve2, -len/2, -curve2, -len/2, 0, -len)
        context.stroke()

        if(len < 8){
            context.beginPath()
            context.arc(0, -len, 10, 0, Math.PI/2)
            context.fill()
            context.restore();
            return;
        }

        drawTree(0, -len, len * 0.75, angle + curve, branchWidth * 0.6);
        drawTree(0, -len, len * 0.75, angle - curve, branchWidth * 0.6);

        context.restore()
    }

    const generarArbol = () => {
        context.clearRect(0, 0, canvasWidth, canvasHeight)
        let centerPointX = canvasWidth / 2;
        let len = Math.floor((Math.random() * 20) + 100)
        let angle = 0
        let branchWidth = (Math.random() * 140) + 1
        curve = (Math.random() * 10) + 10
        curve2 = (Math.random() * 50)
        let color1 = `rgb(${Math.random()*255},${Math.random()*255},${Math.random()*255})`
        let color2 = `rgb(${Math.random()*255},${Math.random()*255},${Math.random()*255})`
        drawTree(centerPointX,canvasHeight-200, len, angle, branchWidth, color1, color2)
        //setBtnColor(color2)
    }

    useEffect(() => {
        if(context)
            generarArbol()
    }, [context])

    const draw = (ctx, frameCount, width, height) => {
        setCanvasWidth(width)
        setCanvasHeight(height)
        setContext(ctx)
    }

    return (
        <>
        <Canvas draw={draw} background='#000' />
        <Lab8Button onClick={generarArbol} background={btnColor}>Generar Arbol</Lab8Button>
        </>
    )
}

export default Lab9

