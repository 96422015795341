import styled, { keyframes } from 'styled-components'
import { Link } from 'react-router-dom'

export const DivPage = styled.div`
    overflow: auto;
    height: calc(100vh - 80px);
`

export const DivPageFlex = styled.div`
    display: flex;
    height: calc(100vh - 80px);
    justify-content: space-between;
    align-items: center;
`

export const DivPageCredits = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
    background: blue;
    color: yellow;
    line-height: 1.3;
    overflow: hidden;
`

export const WrapPage = styled.div`
    display: grid;
    grid-template-columns: 200px auto;
    grid-template-rows: max-content;

    @media screen and (max-width: 768px){
        grid-template-columns: 0 auto;
    }
`
export const SidebarPage = styled.div`
    background-image: linear-gradient(to bottom, #e53240, #221e1f);
    justify-content: stretch;
    height: calc(100vh - 80px);
    grid-column: 1;

    @media screen and (max-width: 768px){
        display: none;
    }

`
export const ContentPage = styled.div`
    grid-column: 2;
    align-items: center;
    justify-items: center;
`

export const LiPage = styled.li`
    list-style-type: none;
    color: #fcfcfc;
`

export const LinkPage = styled(Link)`
    display: flex;
    color: #fcfcfc;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    height: 40px;
    text-decoration: none;
    font-size: 16px;

    &:hover {
        background: #63242a;
        color: #e53240;
        cursor: pointer;
    }
`

export const LinkCredits = styled(LinkPage)`
    display: inline;
    padding: 0px;
    font-size: 4vw;
`

const animCredits = keyframes`
    0% {
        transform: rotateX(21deg) translate3d(0, 100rem, 0);
        opacity: 1;
    }
    85% {
        opacity: 1;
    }
    100% {
        transform: rotateX(21deg) translate3d(0, -150rem, 0);
        opacity: 0;
    }
`

export const CreditsContainer = styled.div`
    margin: 50 auto;
    perspective: 380px;
    perspective-origin: bottom;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    transform: scaleY(0.9);
`

export const Credits = styled.div`
    top: 0%;
    left: 0;
    right: 0;
    height: 1000px;
    overflow: hidden;
    animation: ${animCredits} 20s infinite;
`

export const CreditsP = styled.p`
    font-size: 4vw;
    padding: 0.5rem 0;
    text-align: center;
`



