import axios from 'axios'
import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import { ExampleH2 } from './ExamplesElements'


const Ejemplo3 = () => {
    const [resultado, setResultado] = useState()
    useEffect(() => {
        fetchData();        
    }, [])

    const fetchData = async () => {
        try{
            const {data} = await axios.get('/api/3-airtable')
            setResultado(data);
        }catch(error){
            console.log(error)
        }
    }

    return (
        <>
            <div>Ejemplo 3 Airtable</div>
            {resultado && resultado.map((product) =>{
                const { id, url, name, price } = product
                return (
                    <Link to={"/serverless/ex3?id="+{id}}>
                        <article className="product" id={id}>
                            <img src={url} alt={name}/>
                            <div className="info">
                                <h5>{name}</h5>
                                <h5 class="price">{price}</h5>
                            </div>
                        </article>
                    </Link>
                )
            })}
        </>
    )
}

export default Ejemplo3
