import styled from 'styled-components'

export const MainBoard = styled.div`
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;    
`

export const PostListTitle = styled.h1`
    width: 40%;
    margin: auto;
    text-align: center;
`

export const PostListContainer = styled.div`
`