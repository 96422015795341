import React from 'react'
import { CenterElem, KeyboardElem, KeyboardLineElem, RoundButton } from './CalculatorElements'

function Keyboard({addInput, calculate, clearInput}) {

    return (
        <KeyboardElem>
            <KeyboardLineElem>
                <CenterElem onClick =  { () => {addInput("7") }} >
                    <RoundButton>7</RoundButton>
                </CenterElem>
                <CenterElem onClick =  { () => {addInput("8") }} >
                    <RoundButton>8</RoundButton>
                </CenterElem>
                <CenterElem onClick =  { () => {addInput("9") }} >
                    <RoundButton>9</RoundButton>
                </CenterElem>
                <CenterElem onClick =  { () => {addInput("/") }} >
                    <RoundButton>/</RoundButton>
                </CenterElem>
            </KeyboardLineElem>
            <KeyboardLineElem>
                <CenterElem onClick =  { () => {addInput("4") }} >
                    <RoundButton>4</RoundButton>
                </CenterElem>
                <CenterElem onClick =  { () => {addInput("5") }} >
                    <RoundButton>5</RoundButton>
                </CenterElem>
                <CenterElem onClick =  { () => {addInput("6") }} >
                    <RoundButton>6</RoundButton>
                </CenterElem>
                <CenterElem onClick =  { () => {addInput("*") }} >
                    <RoundButton>x</RoundButton>
                </CenterElem>
            </KeyboardLineElem>
            <KeyboardLineElem>
                <CenterElem onClick =  { () => {addInput("1") }} >
                    <RoundButton>1</RoundButton>
                </CenterElem>
                <CenterElem onClick =  { () => {addInput("2") }} >
                    <RoundButton>2</RoundButton>
                </CenterElem>
                <CenterElem onClick =  { () => {addInput("3") }} >
                    <RoundButton>3</RoundButton>
                </CenterElem>
                <CenterElem onClick =  { () => {addInput("-") }} >
                    <RoundButton>-</RoundButton>
                </CenterElem>
            </KeyboardLineElem>
            <KeyboardLineElem>
                <CenterElem onClick =  { () => {addInput("0") }} >
                    <RoundButton>0</RoundButton>
                </CenterElem>
                <CenterElem onClick =  { () => {addInput(".") }} >
                    <RoundButton>.</RoundButton>
                </CenterElem>
                <CenterElem onClick =  { () => { calculate() }} >
                    <RoundButton>=</RoundButton>
                </CenterElem>
                <CenterElem onClick =  { () => {addInput("+") }} >
                    <RoundButton>+</RoundButton>
                </CenterElem>
            </KeyboardLineElem>
            <KeyboardLineElem>
            <CenterElem onClick =  { clearInput } >
                <RoundButton>C</RoundButton>
            </CenterElem>
            </KeyboardLineElem>
        </KeyboardElem>
    )
}

export default Keyboard
