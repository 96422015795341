import axios from 'axios'
import React, {useEffect, useState} from 'react'
import { ExampleH2 } from './ExamplesElements'

const Ejemplo1 = () => {
    const [resultado, setResultado] = useState()
    useEffect(() => {
        fetchData();        
    }, [])

    const fetchData = async () => {
        try{
            const {data} = await axios.get('/api/1-hello')
            setResultado(data);
            console.log(data)
        }catch(error){
            console.log(error.response.data)
            resultado.textContent = error.response.data
        }
    }

    return (
        <>
            <div>Ejemplo 1</div>
            <ExampleH2>{resultado}</ExampleH2>
        </>
    )
}

export default Ejemplo1
