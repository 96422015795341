import React from 'react'
import { StyledFormPlaylist } from './MusicElements'

const FormAddPlaylist = ({handleSubmit, refInput}) => {

    return (
        <StyledFormPlaylist onSubmit={handleSubmit}>
            <div className="title">
                Nueva Playlist
            </div>
            <div className="content-wrap">
                <input type="text" placeholder="Nueva Playlist" required ref={refInput}/>
                <br/>
                <button type="submit">Crear</button>
            </div>
        </StyledFormPlaylist>
    )
}

export default FormAddPlaylist
