import React, { useState, useEffect } from 'react'
import SplitPane from 'react-split-pane'
import { useParams } from 'react-router-dom'
import { CssEditor, HtmlEditor, JavascriptEditor } from './Editor'
import useFetch from '../../utils/useFetch';
import './Editor.css'
import { useDebounce } from '../../utils/useDebounce';

const Editor = () => {
    const [heightValue, setHeightValue] = useState('600px');
    const [htmlValue, setHtmlValue] = useState("");
    const [cssValue, setCssValue] = useState("");
    const [jsValue, setJsValue] = useState("");
    const [outputValue, setOutputValue] = useState("");
    const [saving, setSaving] = useState(false);
    const { id } = useParams();
    const { data, error, isPending } = useFetch('http://localhost:8000/data/'+id);


    const debouncedHtml = useDebounce(htmlValue, 1000);
    const debouncedCss = useDebounce(cssValue, 1000);
    const debouncedJs = useDebounce(jsValue, 1000);

    useEffect(() => {
        console.log('Cambiado ID', id)
        if(!error){
            setHtmlValue(data.html);
            setCssValue(data.css);
            setJsValue(data.js);
        }
    }, [data])

    useEffect(() => {
        const output = `<html>
            <style>${debouncedCss}</style>
            <body>
                ${debouncedHtml}
                <script type="text/javascript">
                    ${debouncedJs}
                </script>
            </body>
        </html>`;
        setOutputValue(output);
    }, [debouncedHtml, debouncedCss, debouncedJs])

    const save = async () => {
        setSaving(true);
        const requestOptions = {
            method: !id ? "PUT" : "POST",
            headers: { "Content-Type": "application/json"},
            body: JSON.stringify({
                html: htmlValue,
                css: cssValue,
                js: jsValue,
                id: id
            })
        }
        const response = await fetch(`http://localhost:8000/data/${id}`, requestOptions);
    }

    if(isPending){
        return <div className="loading">Loading...</div>
    }

    return (
        <>
          <div className="header">
            <button className="button">Nuevo</button>
            <button className="button">Grabar</button>
          </div>
          <SplitPane split="horizontal" style={{position: "relative"}}
            minSize={"50%"}
            onDragFinished={(height) => {
                setHeightValue(`${height -40}px`)
            }}>
            <SplitPane split="vertical" minSize={"33%"}>
                <HtmlEditor 
                    height={heightValue}
                    value={htmlValue}
                    onChange={setHtmlValue}/>
                <SplitPane split="vertical" minSize={"50%"}>
                    <CssEditor 
                        height={heightValue}
                        value={cssValue}
                        onChange={setCssValue}/>
                    <JavascriptEditor 
                        height={heightValue}
                        value={jsValue}
                        onChange={setJsValue}/>
                </SplitPane>
            </SplitPane>
            <iframe srcDoc={outputValue} className="previewIframe" title="previewFrame" />
          </SplitPane>
        </>
    )
}

export default Editor;