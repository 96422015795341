import React from 'react'
import { PostListContainer } from './BoardElements'

function PostList() {
    return (
        <PostListContainer>
        </PostListContainer>
    )
}

export default PostList
