import React from 'react'
import Editor from '../components/Editor'

function Labs() {
    return (
        <div >
        <Editor/>
        </div>
    )
}

export default Labs
