import React from 'react'
import { ScreenElem } from './CalculatorElements'

function Screen({input}) {
    return (
        <ScreenElem>
            <div>{input}</div>
        </ScreenElem>
    )
}

export default Screen
