import React, {useContext} from 'react'
import { StoreContext } from '.'
import { DivContent, DivPlayListTitle } from './MusicElements'

const Content = ({children}) => {
    const { state } = useContext(StoreContext)
    const currentPlaylist = state.currentPlaylist
    const songIds = Array.from(state.playlists[currentPlaylist])

    return (
        <DivContent>
            <DivPlayListTitle>{currentPlaylist}</DivPlayListTitle>
            <table>
                <thead>
                    <tr>
                        <td>Titulo</td>
                        <td>Artista</td>
                        <td>Duracion</td>
                    </tr>
                </thead>
                <tbody>
                </tbody>
            </table>
        </DivContent>
    )
}

export default Content
