import React from 'react'
import MicroComputers from '../components/Microcomputers'
import { DivPage } from './PagesElements'

const Retro = () => {
    return (
        <DivPage>
            <MicroComputers/>
        </DivPage>
    )
}

export default Retro
