import React, { useEffect, useState } from 'react'
import Canvas from '../../Canvas'
import { DivBoard } from '../GamesElements'

const Runner = () => {
    const [canvasWidth, setCanvasWidth] = useState(600)
    const [canvasHeight, setCanvasHeight] = useState(600)
    const [context, setContext] = useState(null)
    const [playerState, setPlayerState] = useState('run')
    const playerImage = new Image()
    playerImage.src = '/assets/shadow_dog.png'
    const spriteWidth = 575
    const spriteHeight = 523
    let gameFrame = 0;
    const staggerFrames = 5
    const spriteAnimations = [];
    const animationStates = [
        {
            name: 'idle',
            frames: 7
        },
        {
            name: 'jump',
            frames: 7
        },
        {
            name: 'fall',
            frames: 7
        },
        {
            name: 'run',
            frames: 9
        },
        {
            name: 'dizzy',
            frames: 11
        },
        {
            name: 'sit',
            frames: 5
        },
        {
            name: 'roll',
            frames: 7
        },
        {
            name: 'bite',
            frames: 7
        },
        {
            name: 'ko',
            frames: 12
        },
        {
            name: 'gethit',
            frames: 4
        }
    ];
    animationStates.forEach((state, index) => {
        let frames = {
            loc: []
        }
        for(let j=0;j<state.frames; j++){
            let positionX = j * spriteWidth;
            let positionY = index * spriteHeight;
            frames.loc.push({x: positionX, y: positionY});
        }
        spriteAnimations[state.name] = frames;
    });

    useEffect(() => {
        // Init canvas
        if (context) {
            //createGrid()
        }
    }, [context, canvasWidth, canvasHeight])

    // Variables globales

    const mouse = {
        x: 10,
        y: 10,
        width: 0.1,
        height: 0.1
    }
    const mouseMove = (e) =>{
        mouse.x = e.nativeEvent.offsetX
        mouse.y = e.nativeEvent.offsetY
    }
    const mouseLeave = (e) => {
        mouse.x = undefined;
        mouse.y = undefined;
    }

    const mouseClick = (e) =>{
    }

    const onChangeAnimation = (event) => {
        event.preventDefault();
        setPlayerState(event.target.value);
    }


    

    
   

    
    // Utilidades

    const draw = (ctx, frameCount, width, height) => {
        setCanvasWidth(width)
        setCanvasHeight(height)
        setContext(ctx)
        ctx.clearRect(0,0,width, height)
        let position = Math.floor(gameFrame / staggerFrames) % spriteAnimations[playerState].loc.length;
        let frameX = spriteWidth * position;
        let frameY = spriteAnimations[playerState].loc[position].y;
        ctx.drawImage(playerImage, frameX, frameY, spriteWidth, spriteHeight, 0, 0, spriteWidth, spriteHeight);
        

        if(context){
            
        }
        /*if(gameFrame % staggerFrames == 0){
            if(frameX < 6) frameX++;
            else frameX = 0;
        }*/
        gameFrame++;
        
    }
    return (
        <>
            <div>
                <label for="animations">Animacion:</label>
                <select id='animations' name='animations' onChange = {onChangeAnimation}>
                    <option value='idle'>Idle</option>
                    <option value='jump'>Jump</option>
                    <option value='fall'>Fall</option>
                    <option value='run'>Run</option>
                    <option value='dizzy'>dizzy</option>
                    <option value='sit'>Sit</option>
                    <option value='roll'>Roll</option>
                    <option value='bite'>Bite</option>
                    <option value='ko'>KO</option>
                    <option value='gethit'>GetHit</option>
                </select>
            </div>
            <DivBoard>
                <Canvas draw={draw} width={'900px'} height={'600px'} background={'#fff'} onMouseMove={mouseMove} onMouseLeave={mouseLeave} onClick={mouseClick}/>
            </DivBoard>
        </>
    )
}

export default Runner
