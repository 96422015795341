import React, { useState, useEffect } from 'react'
import Canvas from '../../Canvas'
import { DivCanvas } from '../GamesElements'

const Parallax = () => {
	const [canvasWidth, setCanvasWidth] = useState(1280)
	const [canvasHeight, setCanvasHeight] = useState(700)
	const [context, setContext] = useState(null)
	const [slider, setSlider] = useState(5)

	const onChangeSlider = (event) => {
		event.preventDefault();
		setSlider(event.target.value)
	}

	useEffect(() => {
		gameSpeed = slider
	},[slider])

	let gameSpeed = 5;
	const backgroundLayer1 = new Image()
	backgroundLayer1.src = '/assets/parallax/layer-1.png'
	const backgroundLayer2 = new Image()
	backgroundLayer2.src = '/assets/parallax/layer-2.png'
	const backgroundLayer3 = new Image()
	backgroundLayer3.src = '/assets/parallax/layer-3.png'
	const backgroundLayer4 = new Image()
	backgroundLayer4.src = '/assets/parallax/layer-4.png'
	const backgroundLayer5 = new Image()
	backgroundLayer5.src = '/assets/parallax/layer-5.png'
	const backgroundLayer6 = new Image()
	backgroundLayer6.src = '/assets/parallax/layer-6.png'
	const backgroundLayer7 = new Image()
	backgroundLayer7.src = '/assets/parallax/layer-7.png'
	const backgroundLayer8 = new Image()
	backgroundLayer8.src = '/assets/parallax/layer-8.png'
	const backgroundLayer9 = new Image()
	backgroundLayer9.src = '/assets/parallax/layer-9.png'
	const backgroundLayer10 = new Image()
	backgroundLayer10.src = '/assets/parallax/layer-10.png'
	const backgroundLayer11 = new Image()
	backgroundLayer11.src = '/assets/parallax/layer-11.png'

	class Layer {
		constructor(image, speedModifier){
			this.x = 0;
			this.y = 0;
			this.width = 2400;
			this.height= 700;
			this.x2 = this.width;
			this.image = image;
			this.speedModifier = speedModifier;
			this.speed = gameSpeed * this.speedModifier
		}
		update() {
			this.speed = gameSpeed * this.speedModifier
			if(this.x <= -this.width){
				this.x = this.width + this.x2 - this.speed
			}
			if(this.x2 <= -this.width){
				this.x2 = this.width + this.x - this.speed
			}
			this.x = Math.floor(this.x - this.speed)
			this.x2 = Math.floor(this.x2 - this.speed)
		}
		draw(ctx) {
			ctx.drawImage(this.image, this.x, this.y, this.width, this.height);		
			ctx.drawImage(this.image, this.x2, this.y, this.width, this.height);		
		}
	}

	const layer1 = new Layer(backgroundLayer1, 0.00625)
	const layer2 = new Layer(backgroundLayer2, 0.0125)
	const layer3 = new Layer(backgroundLayer3, 0.025)
	const layer4 = new Layer(backgroundLayer4, 0.05)
	const layer5 = new Layer(backgroundLayer5, 0.10)
	const layer6 = new Layer(backgroundLayer6, 0.25)
	const layer7 = new Layer(backgroundLayer7, 0.40)
	const layer8 = new Layer(backgroundLayer8, 0.55)
	const layer9 = new Layer(backgroundLayer9, 0.70)
	const layer10 = new Layer(backgroundLayer10, 0.85)
	const layer11 = new Layer(backgroundLayer11, 1)

	const gameObjects = [layer1,layer2,layer3,layer4,layer5,layer6,layer7,layer8,layer9,layer10,layer11]

	const draw = (ctx, frameCount, width, height) => {
		setCanvasWidth(width)
		setCanvasHeight(height)
		setContext(ctx)
		ctx.clearRect(0,0,width, height)

		gameObjects.forEach(obj => {
			obj.update()
			obj.draw(ctx)
		})
	}

	return (
		<>
			<div>
				<p>Game Speed: <span id='showGameSpeed'></span></p>
				<input type="range" min="0" max="20" value={slider} onChange={onChangeSlider}/>
			</div>
			<DivCanvas width="1280px" height="700px">
				<Canvas draw={draw} width="1280px" height="700px" background={'#000'}/>
			</DivCanvas>
		</>

	)
}

export default Parallax
