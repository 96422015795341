import React, {useEffect, useState} from 'react'
import Canvas from '../Canvas'

const Lab5 = () => {
    const [canvasWidth, setCanvasWidth] = useState(1670)
    const [canvasHeight, setCanvasHeight] = useState(889)

    let particleArray = []
    const numberOfParticles = 150
    let wobble = 1

    let mouse = {
        x: null,
        y: null,
        radius: (canvasHeight/80) * (canvasWidth/80)
    };

    window.addEventListener('mousemove',function(event){
        mouse.x = event.layerX
        mouse.y = event.layerY
    })

    window.addEventListener('mouseout', function(){
        mouse.x = undefined
        mouse.y = undefined
    })

    useEffect(() => {
        for(let i=0;i<numberOfParticles;i++){
            let moveRadius = Math.random() * canvasWidth
            let step = (Math.random()*0.002) + 0.002
            let position = Math.random() * (Math.PI * 2)
            let size = (Math.random() * 25) + 15
            particleArray.push(new Particle(moveRadius, step, position, size))
        }
    }, [canvasWidth, canvasHeight])

    class Particle{
        constructor(moveRadius, step, position, size){
            this.moveRadius = moveRadius
            this.step = step
            this.position = position
            this.size = size
        }
        draw = function(ctx){
            wobble += 0.0005
            let x = (Math.cos(this.position) + Math.sin(wobble)) * this.moveRadius + canvasWidth/2
            let y = (Math.sin(this.position) + Math.cos(wobble))*this.moveRadius + canvasHeight / 2
            //this.drawStar(ctx, x, y, 7, this.size, this.size/23)
            /*ctx.beginPath();
            ctx.arc(Math.cos(this.position) * this.moveRadius + canvasWidth/2,
                Math.sin(this.position)*this.moveRadius + canvasHeight / 2, 
                this.size, 0, Math.PI*2);
            ctx.closePath()*/
            ctx.beginPath()
            ctx.arc(x,y,this.moveRadius/10, 0, Math.PI * 2)
            ctx.closePath()
            ctx.fillStyle = 'yellow'
            ctx.fill()
            //ctx.strokeStyle = "white"
            //ctx.stroke()
        }
        // Comprobamos la posicion de cada particula, y la 
        update = function(ctx){
            this.position += this.step
            this.draw(ctx)
        }

        drawStar = function(ctx, positionX, positionY, spikes, outerRadius, innerRadius){
            let rotation = Math.PI/2 * 3
            let x = positionX
            let y = positionY
            let step = Math.PI / spikes
    
            ctx.beginPath()
            ctx.moveTo(positionX, positionY - outerRadius)
            for(let i=0;i<spikes;i++){
                x = positionX + Math.cos(rotation) * outerRadius;
                y = positionY + Math.sin(rotation) * outerRadius;
                ctx.lineTo(x,y)
                rotation += step
    
                x = positionX + Math.cos(rotation) * innerRadius
                y = positionY + Math.sin(rotation) * innerRadius
                ctx.lineTo(x,y)
                rotation += step
            }
            ctx.lineTo(positionX, positionY - outerRadius)
            ctx.closePath()
        }
    }

    

    const draw = (ctx, frameCount) => {
        setCanvasWidth(ctx.canvas.width)
        setCanvasHeight(ctx.canvas.height)
        ctx.fillStyle = 'rgba(0,10,200,0.07)'
        ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height)
        for(let i=0;i<particleArray.length;i++){
            particleArray[i].update(ctx);
        }
    }

    return (
        <Canvas draw={draw} background='#000' />
    )
}

export default Lab5
