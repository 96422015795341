const Tm = {
    interval: 150,
    lastCall: Date.now(),
    entities: new Set(),
    addEntity: (entity) => Tm.entities.add(entity),
    removeEntity: (entity) => Tm.entities.remove(entity),
    refresh: () => {
        Tm.entities.forEach(e => e.refresh())
        Tm.currentIndex = 0
    },
    currentIndex: 0,
    turn: () => {
        if(Tm.entities.size > 0){
            let entities = [...Tm.entities]
            let e = entities[Tm.currentIndex]
            if(!e.over()){
                e.turn()
            }else{
                Tm.currentIndex++
            }
        }
    },
    over: () => [...Tm.entities].every(e => e.over())
}

export default Tm;