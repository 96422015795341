import axios from 'axios'
import React, {useEffect, useState} from 'react'
import { ExampleH2 } from './ExamplesElements'

const url = '/api/2-basic-api'

const Ejemplo2 = () => {
    const [resultado, setResultado] = useState([])

    useEffect(() => {
        fetchData();        
    }, [])

    const fetchData = async () => {
        try{
            const {data} = await axios.get(url)
            setResultado(data);
        }catch(error){
        }
    }

    return (
        <>
            <div>Ejemplo 2 Api Simple</div>
            {resultado && resultado.map((product) =>{
                const { image: {url}, name, price} = product
                return (
                    <article className="product">
                        <img src={url} alt={name}/>
                        <div className="info">
                            <h5>{name}</h5>
                            <h5 className="price">{price}</h5>
                        </div>
                    </article>
                )
            })}
        </>
    )
}

export default Ejemplo2
