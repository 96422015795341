import styled from 'styled-components'
import { Link } from "react-scroll";

export const HomeWrapper = styled.div`
    overflow: auto;
    height: calc(100vh - 80px);
    scroll-behaviour: smooth;
`

export const HomeSection = styled.section`
    background: ${({dark}) => (dark ? '#221e1f' : '#fcfcfc')};
    color: ${({dark}) => (dark ? '#fcfcfc' : '#221e1f')};
    display: flex;
    flex-direction: ${({direction}) => (direction ? direction : 'row')};
    justify-content: space-space-between;
    padding: 80px;

    h2 {
        font-size: calc(2em + 2vw);
    }

    .headshot {
        border-radius: 150px;
    }

    .headshot-container {
        display: flex;
        align-items: center;
    }

    .project-container {
        display: flex;
        justify-content: space-around;
    }

    .project-image {
        margin-top: 25px;
        width: 150px;
        height: 150px;
        border-radius: 15px;
    }

    .project-card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 400px;
        height: 500px;
        box-shadow: 5px 5px 20px gray;
        margin: 10px;
        align-items: center;
    }

    .subtext {
        font-size: 12px;
        margin-left: 20px;
        margin-right: 20px;
    }

    hr {
        margin-left: 20px;
        margin-right: 20px;
    }

    .project-link{
        text-decoration: none;
        color: #e53240;
        font-size: 16px;
    }

    @media only screen and (max-width: 1000px){
        .project-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
    }
`

export const HomeHero = styled.div`
    height: calc(100vh - 80px);
    background-color: #fcfcfc;

    h1 {
        font-size: calc(3em + 3vw);
    }

    .hero-text{
        margin-left: 80px;
        margin-bottom: 200px;
        color: #63242a;
    }

    .hero-area{
        display: flex;
        height: 100%;
        justify-content: space-between;
        align-items: center;
    }

    .socials {
        padding-bottom: 200px;
        color: #221e1f;
        font-size: 3em;
    }

    .social {
        color: #221e1f;
        margin: 30px;
        text-decoration: none;

        &:hover{
            color: #e53240;
        }
    }

    .button {
        width: 300px;
        height: 40px;
        margin: 10px;
        border-radius: 10px;
        background-color: #63242a;
        padding-top: 8px;
        font-size: 20px;


        &:hover {
            background-color: #a42b35;
        }

        &:active {
            background-color: darkgray;
            color: #808080;
        }
    }

    p{ 
        margin: 0;
        font-size: 1em;
    }

    @media only screen and (max-width: 1000px){
        .hero-area{
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 200px;
        }
        .hero-text {
            margin-left: 20px;            
            margin-bottom: 0;            
        }
        img{
            width: 20em;
        }
        .socials {
            padding-bottom: 0px;
            display: flex;
            margin-right: auto;
            margin-left: auto;
        }
    }
`

export const ButtonScroll = styled(Link)`
    width: 300px;
    height: 40px;
    margin: 10px;
    border-radius: 10px;
    background-color: coral;
    padding-top: 8px;
    font-size: 20px;


    &:hover {
        background-color: #ef744b;
    }

    &:active {
        background-color: darkgray;
        color: #808080;
    }
`


export const HomeFooter = styled.footer`
    height: 500px;
    background-color: #221e1f;
    color: white;
    text-align: center;
    padding: 80px;
`