import React from 'react'
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/mode-css";
import "ace-builds/src-noconflict/theme-monokai";
import './Editor.css'

export const JavascriptEditor = (props) => {
    return <Editor title={"Javascript"} mode="javascript" {...props}/>
}

export const HtmlEditor = (props) => {
    return <Editor title={"HTML"} mode="html" {...props}/>
}

export const CssEditor = (props) => {
    return <Editor title={"CSS"} mode="css" {...props}/>
}

const Editor = ({mode, onChange, value, title, height}) => {
    return (
        <div className="editorContainer">
            <div className="editorTitle">{title}</div>
            <AceEditor
                mode={mode}
                theme="monokai"
                name={title}
                onChange={onChange}
                fontSize={14}
                width={"100%"}
                height={height}
                value={value}
                showPrintMargin={true}
                showGutter={true}
                tabSize={2}                
                setOptions={{
                    enableBasicAutocompletion: false,
                    enableLiveAutocompletion: false,
                    enableSnippets: false,
                    showLineNumbers: true,
                    tabSize: 2,
                }}
            />
        </div>
    )
}
