import React from 'react'
import { MainBoard, PostListTitle } from './BoardElements'
import Layout from './Layout'
import PostList from './PostList'

const Board = ({title, description, ...props}) => {
    return (
        <>
          <Layout pageTitle={title}>
            <PostListTitle>Boulletin Board System</PostListTitle>
            <MainBoard>
              <PostList></PostList>
            </MainBoard>
          </Layout>

        </>
    )
}

export default Board
