import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const NavIcon = styled(Link)`
    margin-right: 1rem;
    font-size: 2rem;
    height: 80px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`

export const NavLogo = styled(Link)`
    margin-left: 2rem;
    font-size: 2rem;
    display: flex;
    justify-content: flex-start;
    text-decoration: none;
    color: #fcfcfc;
    align-items: center;
`

export const SidebarNav = styled.div`
    background-image: linear-gradient(to bottom right, #e53240, #221e1f);
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: right;
    position: fixed;
    top: 0;
    right: ${({sidebar}) => (sidebar ? '0' : '-100%')};
    transition: 350ms;
    z-index: 20000;
`

export const SidebarWrap = styled.div`
    width: 100%;
`

export const SidebarLink = styled(Link)`
    display: flex;
    color: #fcfcfc;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    height: 40px;
    text-decoration: none;
    font-size: 16px;

    &:hover {
        background: #63242a;
        color: #e53240;
        border-right: 4px solid #fcfcfc;
        cursor: pointer;
    }
`

export const SidebarLabel = styled.span`
    margin-left: 16px;
`

export const DropdownLink = styled(Link)`
    height: 20px;
    color: #fcfcfc;
    text-decoration: none;
    padding-left: 3rem;
    display: flex;
    align-items: center;
    font-size: 14px;

    &:hover {
        background: #3EEC06;
        color: blue;
        border-right: 4px solid #fcfcfc;
        cursor: pointer;
    }
`