import React, {useEffect} from 'react'
import Canvas from '../Canvas'

const Lab2 = () => {

    let particleArray = []

    useEffect(() => {
        for(let i=0;i<150;i++){
            let size = Math.random() * 20
            let x = Math.random() * 2000;
            let y = Math.random() * 2000;
            let directionX = (Math.random() * .4) - .2;
            let directionY = (Math.random() * .4) - .2;
            let color = 'white'
            particleArray.push(new Particle(x,y,directionX, directionY, size, color))
        }
    }, [])

    function Particle(x,y, directionX, directionY, size, color){
        this.x = x
        this.y = y
        this.directionX = directionX
        this.directionY = directionY
        this.size = size
        this.color = color
    }
    Particle.prototype.draw = function(ctx){
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2, false);
        ctx.fillStyle = this.color
        ctx.fill()
    }
    Particle.prototype.update = function(ctx){
        if(this.x + this.size > 2000 || this.x - this.size < 0){
            this.directionX = -this.directionX
        }
        if(this.y + this.size > 2000 || this.y - this.size < 0){
            this.directionY = -this.directionY
        }
        this.x += this.directionX;
        this.y += this.directionY;
        this.draw(ctx)
    }    

    const draw = (ctx, frameCount) => {
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
        for(let i=0;i<particleArray.length;i++){
            particleArray[i].update(ctx);
        }
    }

    return (
        <Canvas draw={draw} background='linear-gradient(#25364f,#4d71a5)'/>
    )
}

export default Lab2
