import React from 'react'
import { DivPlayBar } from './MusicElements'

const PlayBar = ({children}) => {
    return (
        <DivPlayBar>
            {children}
        </DivPlayBar>
    )
}

export default PlayBar
