import React from 'react'
import * as AiIcons from 'react-icons/ai'
import FaceComponent from '../../components/GenerateFaces/FaceComponent'
import { HomeSection, HomeFooter, HomeHero, HomeWrapper } from './HomeElements'
import img1 from '../../assets/images/proj_1.svg'
import img2 from '../../assets/images/proj_2.svg'
import img3 from '../../assets/images/proj_3.svg'
import img4 from '../../assets/images/proj_4.svg'
import logo from './logo.svg'
import { Link } from 'react-router-dom'
import { Link as LinkS } from 'react-scroll'


function Home() {
    /*return (
        <DivPageCredits>
            <CreditsContainer>
                <Credits>
                    <CreditsP><LinkCredits to="/home">Juegos Juegos Juegos</LinkCredits></CreditsP>
                </Credits>
                <Credits>
                    <CreditsP><LinkCredits to="/labs">Labs Labs Labs</LinkCredits></CreditsP>
                </Credits>
                <Credits>
                    <CreditsP><LinkCredits to="/retro">Retro Retro Retro</LinkCredits></CreditsP>
                </Credits>
                <Credits>
                    <CreditsP><LinkCredits to="/music">Music Music Music</LinkCredits></CreditsP>
                </Credits>
                <Credits>
                    <CreditsP><LinkCredits to="/board">Board Board Board</LinkCredits></CreditsP>
                </Credits>
            </CreditsContainer>
        </DivPageCredits>
    )*/

    return (
        <HomeWrapper id="homewrapper">
            <HomeHero>
                <div className="hero-area">
                    <div className='hero-text'>
                        <img src={logo} alt="Rafa Ruiz"/>
                        <p>Desarrollador</p>
                        <div className="button"><LinkS to="aboutme" containerId="homewrapper" spy={true} smooth={true} duration={500}>Acerca de mi</LinkS></div>
                        <div className="button"><LinkS to="projects" containerId="homewrapper" spy={true} smooth={true} duration={500}>Proyectos</LinkS></div>
                    </div>
                    <div className="socials">
                        <div><a href="https://www.linkedin.com/in/rafael-ruiz-navas" className="social"><AiIcons.AiFillLinkedin/></a></div>
                        <div><a href="https://www.twitter.com" className="social"><AiIcons.AiFillTwitterSquare/></a></div>
                        <div><a href="https://github.com/rafaelruiznavas" className="social"><AiIcons.AiFillGithub/></a></div>
                    </div>
                </div>
            </HomeHero>
            <HomeSection name="aboutme" dark={true} id="aboutme">
                <div className="information">
                    <h2>Acerca de mi</h2>
                    <p>Saludos a todos</p>
                </div>
            </HomeSection>
            <HomeSection direction="column" id="projects">
                <h2>Proyectos</h2>
                <div className="project-container">
                    <div className="project-card">
                        <img className="project-image" src={img1} alt="Proyecto 1"/>
                        <h3>Canvas</h3>
                        <p className="subtext">Generacion de graficos usando canvas HTML</p>
                        <hr/>
                        <p className="subtext"><Link className="project-link" to="/canvas">Ver</Link></p>
                    </div>
                    <div className="project-card">
                        <img className="project-image" src={img2} alt="Proyecto 2"/>
                        <h3>Micro computadores</h3>
                        <p className="subtext">Base de datos de micro computadores</p>
                        <hr/>
                        <p className="subtext"><Link className="project-link" to="/retro">Ver</Link></p>
                    </div>
                    <div className="project-card">
                        <img className="project-image" src={img3} alt="Proyecto 3"/>
                        <h3>Musica</h3>
                        <p className="subtext">Reproductor de musica</p>
                        <hr/>
                        <p className="subtext"><Link className="project-link" to="/music">Ver</Link></p>
                    </div>
                    <div className="project-card">
                        <img className="project-image" src={img4} alt="Proyecto 4"/>
                        <h3>Juegos</h3>
                        <p className="subtext">Juegos para el navegador</p>
                        <hr/>
                        <p className="subtext"><Link className="project-link" to="/games">Ver</Link></p>
                    </div>
                </div>
            </HomeSection>
            <HomeFooter>
                <h2>Contactar</h2>
                <p>rafa@ruiznavas.com</p>
            </HomeFooter>
        </HomeWrapper>
    )
}

export default Home
