import React from 'react'
import Board from '../components/Board'
import { DivPage } from './PagesElements'

const BoardPage = () => {
    return (
        <>
            <DivPage>
                <Board/>
            </DivPage>
        </>
    )
}

export default BoardPage
