import React, {useEffect, useState} from 'react'
import Canvas from '../Canvas'
import { Lab8Button } from './LabsElements'

const Lab8 = () => {
    const [canvasWidth, setCanvasWidth] = useState(1670)
    const [canvasHeight, setCanvasHeight] = useState(889)
    const [context, setContext] = useState(null)

    let mouse = {
        x: undefined,
        y: undefined,
        radius: 100
    };

    window.addEventListener('mousemove',function(event){
        mouse.x = event.layerX
        mouse.y = event.layerY
    })

    window.addEventListener('mouseout', function(){
        mouse.x = undefined
        mouse.y = undefined
    })

    function drawTree(startX, startY, len, angle, branchWidth, color1, color2){
        context.beginPath();
        context.save();
        context.strokeStyle = color1;
        context.fillStyle = color2;
        context.lineWidth = branchWidth;
        context.translate(startX, startY)
        context.rotate(angle * Math.PI/180)
        context.moveTo(0,0)
        context.lineTo(0, -len)
        context.stroke()

        if(len < 5){
            context.restore();
            return;
        }

        drawTree(0, -len, len * 0.75, angle + 7, branchWidth);
        drawTree(0, -len, len * 0.75, angle - 7, branchWidth);

        context.restore()
    }

    const generarArbol = () => {
        context.clearRect(0, 0, canvasWidth, canvasHeight)
        drawTree(canvasWidth/2,canvasHeight-80, 120, 1, 2, 'brown', 'green')
    }

    useEffect(() => {
        if(context)
            drawTree(canvasWidth/2,canvasHeight-80, 120, 0, 2, 'brown', 'green')
    }, [context])

    const draw = (ctx, frameCount, width, height) => {
        setCanvasWidth(width)
        setCanvasHeight(height)
        setContext(ctx)
    }

    return (
        <>
        <Canvas draw={draw} background='#000' />
        </>
    )
}

export default Lab8

