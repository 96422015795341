import React from 'react'
import { DivTopBar } from './MusicElements'

const TopBar = ({children}) => {
    return (
        <DivTopBar>
            {children}
        </DivTopBar>
    )
}

export default TopBar
