import React from 'react'
import { BrowserRouter as Router, Route, Switch, useRouteMatch } from 'react-router-dom';
import Lab1 from '../components/Labs/Lab1';
import Lab10 from '../components/Labs/Lab10';
import Lab2 from '../components/Labs/Lab2';
import Lab3 from '../components/Labs/Lab3';
import Lab4 from '../components/Labs/Lab4';
import Lab5 from '../components/Labs/Lab5';
import Lab6 from '../components/Labs/Lab6';
import Lab7 from '../components/Labs/Lab7';
import Lab8 from '../components/Labs/Lab8';
import Lab9 from '../components/Labs/Lab9';
import { DivPage, LinkPage, LiPage, SidebarPage, WrapPage, ContentPage } from './PagesElements'

const CanvasLabs = () => {
    let { path, url } = useRouteMatch();
    return (
        <Router>
            <WrapPage>
                <SidebarPage>
                    <ul>
                        <LiPage>
                            <LinkPage to={`${url}/lab1`}>Lab 1</LinkPage>
                        </LiPage>
                        <LiPage>
                            <LinkPage to={`${url}/lab2`}>Lab 2</LinkPage>
                        </LiPage>
                        <LiPage>
                            <LinkPage to={`${url}/lab3`}>Lab 3</LinkPage>
                        </LiPage>
                        <LiPage>
                            <LinkPage to={`${url}/lab4`}>Lab 4</LinkPage>
                        </LiPage>
                        <LiPage>
                            <LinkPage to={`${url}/lab5`}>Lab 5</LinkPage>
                        </LiPage>
                        <LiPage>
                            <LinkPage to={`${url}/lab6`}>Lab 6</LinkPage>
                        </LiPage>
                        <LiPage>
                            <LinkPage to={`${url}/lab7`}>Lab 7</LinkPage>
                        </LiPage>
                        <LiPage>
                            <LinkPage to={`${url}/lab8`}>Lab 8</LinkPage>
                        </LiPage>
                        <LiPage>
                            <LinkPage to={`${url}/lab9`}>Lab 9</LinkPage>
                        </LiPage>
                        <LiPage>
                            <LinkPage to={`${url}/lab10`}>Lab 10</LinkPage>
                        </LiPage>
                    </ul>
                </SidebarPage>
            <Switch>
                <Route exact path={path}><h3>Selecciona un lab</h3></Route>
                <Route path={`${path}/lab1`}><ContentPage><Lab1/></ContentPage></Route>
                <Route path={`${path}/lab2`}><ContentPage><Lab2/></ContentPage></Route>
                <Route path={`${path}/lab3`}><ContentPage><Lab3/></ContentPage></Route>
                <Route path={`${path}/lab4`}><ContentPage><Lab4/></ContentPage></Route>
                <Route path={`${path}/lab5`}><ContentPage><Lab5/></ContentPage></Route>
                <Route path={`${path}/lab6`}><ContentPage><Lab6/></ContentPage></Route>
                <Route path={`${path}/lab7`}><ContentPage><Lab7/></ContentPage></Route>
                <Route path={`${path}/lab8`}><ContentPage><Lab8/></ContentPage></Route>
                <Route path={`${path}/lab9`}><ContentPage><Lab9/></ContentPage></Route>
                <Route path={`${path}/lab10`}><ContentPage><Lab10/></ContentPage></Route>
            </Switch>
            </WrapPage>
        </Router>
    )
}

export default CanvasLabs
