import React, { useReducer, createContext, useEffect } from 'react'
import useFetch from '../../utils/useFetch'
import Content from './Content'
import { DivMusic } from './MusicElements'
import PlayBar from './PlayBar'
import SideBar from './SideBar'
import TopBar from './TopBar'

export const StoreContext = createContext(null)

const DEFAULT_PLAYLIST = "home"
const urlPlaylist = '/api/musica'
const urlSongs = '/api/media'

const initialState = {
    currentPlaylist: DEFAULT_PLAYLIST,
    media: new Set(),
    playlists: {
        home: new Set()
    }    
}

const reducer = (state, action) => {
//    { type: 'ADD_PLAYLIST', playlist: 'Rock'; }
    switch(action.type){
        case 'ADD_PLAYLIST':
            return { ...state, 
                playlists: { ...state.playlists, [action.playlist] : new Set()}
            }
        case 'SET_PLAYLIST':
            return { ...state, currentPlaylist: action.playlist }
        case 'INIT_PLAYLIST':
            state = action.data
            return state = action.data
        case 'LOAD_SONGS':
            console.log('Loading SONGS', action.data)
            return { ...state, media: action.data}
        default:
            return state
    }
}

const Music = () => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const { data, error, isPending }  = useFetch(urlPlaylist)

    useEffect(() => {
        if(data.playlists){
            dispatch({type: 'INIT_PLAYLIST', data: data})
        }  
    }, [data])

    return (
        <StoreContext.Provider value={{state, dispatch}}>
            <DivMusic>
                <SideBar />
                <Content>
                    <TopBar />
                    <PlayBar />
                </Content>
            </DivMusic>
        </StoreContext.Provider>
    )
}

export default Music
