import React, {useRef, useState, useEffect} from 'react'
import { DivPage } from '../../pages/PagesElements'
import Canvas from '../Canvas'

function Lab1() {
    let number = 0;
    let scale = 10;
    let hue = Math.random() * 360;

    function drawFlower(ctx){
        let angle = number * 3.8;
        let radius = scale * Math.sqrt(number);
        let positionX = radius * Math.sin(angle) + ctx.canvas.width / 2;
        let positionY = radius * Math.cos(angle) + ctx.canvas.height/2;

        //ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
        ctx.fillStyle = 'hsl('+hue+', 100%, 50%)'
        ctx.lineWidth = 4;
        ctx.beginPath()
        ctx.arc(positionX, positionY, 8, 0, 2*Math.PI)
        ctx.closePath()
        ctx.fill()
        ctx.stroke()

        number++;
        hue+=0.5;
    }
    const draw = (ctx, frameCount) => {
        drawFlower(ctx)
    }

    return (
        <Canvas draw={draw} options={{globalCompositeOperation:'destination-over'}}/>
    )
}

export default Lab1
