import React, {useEffect, useState} from 'react'
import Canvas from '../Canvas'

const Lab4 = () => {
    const [canvasWidth, setCanvasWidth] = useState(1670)
    const [canvasHeight, setCanvasHeight] = useState(889)

    let particleArray = []
    const colors = [
        'white',
        'rgba(255,255,255,0.3)',
        'rgba(173,216,230,0.8)',
        'rgba(211,211,211,0.8)',
    ]
    const maxSize = 40;
    const minSize = 0;
    const mouseRadius = 60;
    const constante = 1
    const numberOfParticles = (canvasHeight * canvasWidth) / 9000;

    let mouse = {
        x: null,
        y: null,
        radius: (canvasHeight/160) * (canvasWidth/160)
    };

    window.addEventListener('mousemove',function(event){
        mouse.x = event.layerX
        mouse.y = event.layerY
    })

    window.addEventListener('mouseout', function(){
        mouse.x = undefined
        mouse.y = undefined
    })

    useEffect(() => {
        for(let i=0;i<numberOfParticles*2;i++){
            let size = (Math.random() * 2) + 1            
            let x = (Math.random() * ((canvasWidth - size * 2) - (size * constante)) + size * constante);
            let y = (Math.random() * ((canvasHeight - size * 2) - (size * constante)) + size * constante);
            let directionX = (Math.random() * 5) - 2.5;
            let directionY = (Math.random() * 5) - 2.5;
            let color = '#8c5523'
            particleArray.push(new Particle(x,y,directionX, directionY, size, color))
        }
    }, [])

    class Particle{
        constructor(x,y, directionX, directionY, size, color){
            this.x = x
            this.y = y
            this.directionX = directionX
            this.directionY = directionY
            this.size = size
            this.color = color
        }
        draw = function(ctx){
            ctx.beginPath();
            ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2, false);
            ctx.fillStyle = '#8c5523'
            ctx.fill()
        }
        // Comprobamos la posicion de cada particula, y la 
        update = function(ctx){
            if(this.x  > canvasWidth || this.x  < 0){
                this.directionX = -this.directionX
            }
            if(this.y  > canvasHeight || this.y < 0){
                this.directionY = -this.directionY
            }
            

            // Comprobamos la colision de la particula con el raton
            let dx = mouse.x - this.x
            let dy = mouse.y - this.y
            let distance = Math.sqrt(dx*dx + dy*dy)
            if(distance < mouse.radius + this.size){
                if(mouse.x < this.x && this.x < canvasWidth - this.size * 100){
                    this.x += 10;
                }
                if(mouse.x > this.x && this.x > this.size * 10){
                    this.x -= 10;
                }
                if(mouse.y < this.y && this.y < canvasHeight - this.size * 10){
                    this.y += 10;
                }
                if(mouse.y > this.y && this.y > this.size * 10){
                    this.y -= 10;
                }
            }
            this.x += this.directionX;
            this.y += this.directionY;
            this.draw(ctx)
        }
    }

    const connect = (ctx) => {
        let opacityValue = 1;
        for(let a = 0;a<particleArray.length;a++){
            for(let b=a;b<particleArray.length;b++){
                let distance = ((particleArray[a].x - particleArray[b].x) * (particleArray[a].x - particleArray[b].x)) 
                    + ((particleArray[a].y - particleArray[b].y) * (particleArray[a].y - particleArray[b].y))
                if(distance < (canvasWidth/7) * (canvasHeight/7)){
                    opacityValue = 1 - (distance/20000);
                    ctx.strokeStyle=`rgba(140,85,31,${opacityValue})`
                    ctx.lineWidth = 1
                    ctx.beginPath()
                    ctx.moveTo(particleArray[a].x, particleArray[a].y)
                    ctx.lineTo(particleArray[b].x, particleArray[b].y)
                    ctx.stroke()
                }
            }
        }
    }

    const draw = (ctx, frameCount) => {
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
        for(let i=0;i<particleArray.length;i++){
            particleArray[i].update(ctx);
        }
        connect(ctx)
    }

    return (
        <Canvas draw={draw} background='radial-gradient(#ffc38c,#ff9b40)' />
    )
}

export default Lab4
