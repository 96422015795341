import styled from 'styled-components'
import useCanvas from '../../utils/useCanvas'

const CanvasBorder = styled.canvas`
    position: relative;
    border: 2px solid black;
    background: ${({background}) => (background ? background : 'blue')};
    width: ${({width}) => (width ? width : 'calc(100vw - 196px)')};
    height: ${({height}) => (height ? height : 'calc(100vh - 80px)')};
`

const Canvas = props => {
    const { draw, options, ...rest} = props
    //const { globalCompositeOperation, ...moreConfig} = options
    const canvasRef = useCanvas(draw, options)

    return <CanvasBorder ref={canvasRef} {...rest} background={props.background}/>
} 

export default Canvas
