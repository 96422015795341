import styled from 'styled-components'

export const CalcElem = styled.div`
    display: flex;
    flex-direction: column;
    width: 450px;
    box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.6);
    border-radius: 20px;   
    background-color: #221e1f;
    padding: 20px;
    margin-top: 20px;
    justify-content: space-between;
    margin: 20px auto;
`

export const ScreenElem = styled.div`
    height: 80px;
    background-color: #fcfcfc;
    border-radius: 10px;
    font-size: 40pt;
    justify-content: flex-end;
    align-items: center;
    align-content: center;;
    text-align: center;
    display: flex;
    padding-right: 10px;
`

export const KeyboardElem = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`

export const KeyboardLineElem = styled.div`
    display: flex;
    flex: 1;
    padding: 5px;
`

export const CenterElem = styled.div`
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1;
`

export const RoundButton = styled.div`
    border-radius: 10px;
    background-color: #63242a;
    justify-content: center;
    align-items: center;
    align-content: center;;
    text-align: center;
    display: flex;
    width: 80px;
    height: 50px;
    cursor: pointer;
    margin: 10px 10px 0px 0px;
`

export const ClearButton = styled.div`
    color: #fff;
    cursor: pointer;

`

export const CalcHeader = styled.div`
    color: #fff;
    padding: 10px;
`