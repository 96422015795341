import './App.css';
import Navbar from './components/Navbar/Navbar';
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Home from './pages/Home';
import Games from './pages/Games';
import Labs from './pages/Labs';
import Tetris from './components/Games/Tetris';
import NanoDungeon from './components/Games/NanoDungeon';
import Ejemplo1 from './components/Examples/Ejemplo1';
import Ejemplo2 from './components/Examples/Ejemplo2';
import BoardPage from './pages/BoardPage';
import Ejemplo3 from './components/Examples/Ejemplo3';
import CanvasLabs from './pages/CanvasLabs';
import Retro from './pages/Retro';
import TowerDefense from './components/Games/TowerDefense';
import Music from './components/Music';
import Chat from './pages/Chat';
import Platform from './components/Games/Platform';
import VMachine from './pages/VMachine';
import Video from './pages/Video';
import Runner from './components/Games/Runner';
import G2048 from './components/Games/G2048';
import Parallax from './components/Games/Parallax';
import Match3 from './components/Games/Match3';
import { ContentPage } from './pages/PagesElements';
import Chip8 from './components/Emulators/Chip8';
import Calculator from './components/Calculator';

function App() {
  return (
    <>
      <Router>
      <Navbar/>
      <Switch>
        <Route path="/" exact ><Home/></Route>

        <Route path="/retro"><Retro/></Route>

        <Route path={["/labs/:id","/labs/"]}><Labs/></Route>

        <Route path="/games" exact><Games/></Route>
        <Route path="/canvas" exact><CanvasLabs/></Route>
        <Route path="/vm" exact><VMachine/></Route>

        <Route path={`/games/tetris`}><Games><Tetris/></Games></Route>
        <Route path={`/games/towerdefense`}><Games><TowerDefense/></Games></Route>
        <Route path={`/games/shootemup`}><Games><Platform/></Games></Route>
        <Route path={`/games/runner`}><Games><Runner/></Games></Route>
        <Route path={`/games/parallax`}><Games><Parallax/></Games></Route>
        <Route path={`/games/2048`}><Games><G2048/></Games></Route>
        <Route path={`/games/Match3`}><Games><Match3/></Games></Route>


        <Route path="/serverless/ex1"><Ejemplo1/></Route>
        <Route path="/serverless/ex2"><Ejemplo2/></Route>
        <Route path="/serverless/ex3"><Ejemplo3/></Route>

        <Route path="/calc"><Calculator/></Route>

        <Route path="/board" exact><BoardPage/></Route>

        <Route path={`/emu/chip8`}><Chip8/></Route>

        <Route path="/music"><Music/></Route>
        <Route path="/chat"><Chat/></Route>
        <Route path={["/video/:id","/video/"]}><Video /></Route>
      </Switch>
      </Router>
    </>
  );
}

export default App;
