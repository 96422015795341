import React from 'react'
import { BrowserRouter as Router, Route, Switch, useRouteMatch } from 'react-router-dom';
import G2048 from '../components/Games/G2048';
import Parallax from '../components/Games/Parallax';
import Platform from '../components/Games/Platform';
import Runner from '../components/Games/Runner';
import Tetris from '../components/Games/Tetris';
import TowerDefense from '../components/Games/TowerDefense';
import Match3 from '../components/Games/Match3';
import { LinkPage, LiPage, SidebarPage, WrapPage, ContentPage } from './PagesElements'

function Games({children}) {

    let { path, url } = useRouteMatch();
    return (
            <WrapPage>
                <SidebarPage>
                    <ul>
                        <LiPage><LinkPage to={`/games/tetris`}>Tetris</LinkPage></LiPage>
                        <LiPage><LinkPage to={`/games/towerdefense`}>Tower Defense</LinkPage></LiPage>
                        <LiPage><LinkPage to={`/games/shootemup`}>Shoot'em up</LinkPage></LiPage>
                        <LiPage><LinkPage to={`/games/runner`}>Runner</LinkPage></LiPage>
                        <LiPage><LinkPage to={`/games/parallax`}>Parallax</LinkPage></LiPage>
                        <LiPage><LinkPage to={`/games/2048`}>2048</LinkPage></LiPage>
                        <LiPage><LinkPage to={`/games/match3`}>Match3</LinkPage></LiPage>
                    </ul>
                </SidebarPage>
                <ContentPage>
                    {children}
                </ContentPage>
            </WrapPage>
    )

    /*
    return (
        <>
        <h1>JUEGOS</h1>
        <ListGames>            
            <ArticleComp>
                <Link to="/games/tetris">Tetris</Link>
            </ArticleComp>
            <ArticleComp>
                <Link to="/games/towerdefense">Tower Defense</Link>
            </ArticleComp>
            <ArticleComp><Link to="/games/platform">Shoot'em up</Link></ArticleComp>
            <ArticleComp><Link to="/games/runner">Runner</Link></ArticleComp>
            <ArticleComp><Link to="/games/parallax">Parallax</Link></ArticleComp>
            <ArticleComp><Link to="/games/g2048">2048</Link></ArticleComp>
        </ListGames>
        </>
    )

    */
}

export default Games
