import React, { useEffect, useState } from 'react'
import { AiFillRedditCircle } from 'react-icons/ai'
import Canvas from '../../Canvas'
import { DivBoard } from '../GamesElements'

const TowerDefense = () => {
    const [canvasWidth, setCanvasWidth] = useState(1670)
    const [canvasHeight, setCanvasHeight] = useState(889)
    const [context, setContext] = useState(null)

    useEffect(() => {
        if (context) {
            createGrid()
        }
    }, [context, canvasWidth, canvasHeight])

    // Variables globales
    const cellSize = 100
    const cellGap = 3
    const gameGrid = []
    const defenders = []
    const defenderCost = 100;
    let numberOfResources = 300;
    const enemies = []
    const enemiesPositions = []
    let enemyInterval = 600
    let frame = 0
    let gameOver = false
    let score = 0
    const projectiles = []
    const resources = []
    const winningScore = 500;

    const mouse = {
        x: 10,
        y: 10,
        width: 0.1,
        height: 0.1
    }
    const mouseMove = (e) =>{
        mouse.x = e.nativeEvent.offsetX
        mouse.y = e.nativeEvent.offsetY
    }
    const mouseLeave = (e) => {
        mouse.x = undefined;
        mouse.y = undefined;
    }

    const mouseClick = (e) =>{
        const gridPositionX = mouse.x - (mouse.x % cellSize) + cellGap
        const gridPositionY = mouse.y - (mouse.y % cellSize) + cellGap
        if(gridPositionY < cellSize) return;
        for(let i =0;i<defenders.length;i++){
            if(defenders[i].x === gridPositionX && defenders[i].y === gridPositionY)
                return;
        }
        let defenderCost = 100
        if(numberOfResources >= defenderCost){
            defenders.push(new Defender(gridPositionX,gridPositionY))
            numberOfResources -= defenderCost
        }else{
            floatingMessages.push(new FloatingMessage('Necesitas mas Recursos', mouse.x, mouse.y, 15, 'blue'))
        }
        console.log('Positions', gridPositionX, gridPositionY)
    }

    // Tablero del juego
    const controlsBar = {
        width: canvasWidth,
        height: cellSize
    }
    class Cell {
        constructor(x,y){
            this.x = x
            this.y = y
            this.width = cellSize
            this.height = cellSize
        }
        draw() {
            if(mouse.x && mouse.y && collision(this, mouse)){
                context.strokeStyle = 'black'
                context.strokeRect(this.x, this.y, this.width, this.height)
            }
        }
    }

    const createGrid = () => {
        for(let y = cellSize;y<canvasHeight;y+=cellSize){
            for(let x = 0;x < canvasWidth;x+=cellSize){
                gameGrid.push(new Cell(x,y))
            }
        }
    }

    const handleGameGrid = () => {
        for(let i=0;i<gameGrid.length;i++){
            gameGrid[i].draw()
        }
    }

    const handleDefenders = () => {
        for(let i=0;i<defenders.length;i++){
            defenders[i].draw()
            defenders[i].update()
            if(enemiesPositions.indexOf(defenders[i].y) !== -1){
                defenders[i].shooting = true
            }else{
                defenders[i].shooting = false
            }
            for(let j=0;j<enemies.length;j++){
                if(defenders[i] && collision(defenders[i], enemies[j])){
                    enemies[j].movement = 0
                    defenders[i].health -= 0.2;
                }
                if(defenders[i] && defenders[i].health <= 0){
                    defenders.splice(i,1)
                    i--
                    enemies[j].movement = enemies[j].speed
                }
            }
        }        
    }

    const handleEnemies = () => {
        for(let i=0;i<enemies.length;i++){
            enemies[i].update()
            enemies[i].draw()
            if(enemies[i].x < 0){
                gameOver = true
            }
            if(enemies[i].health <= 0){
                let gainedResources = enemies[i].maxHealth/10;
                floatingMessages.push(new FloatingMessage('+' + gainedResources, enemies[i].x, enemies[i].y, 20, 'black'))
                floatingMessages.push(new FloatingMessage('+' + gainedResources, 250, 50, 20, 'gold'))
                numberOfResources += gainedResources
                score += gainedResources
                const findThisIndex = enemiesPositions.indexOf(enemies[i].y)
                enemiesPositions.splice(findThisIndex, 1)
                enemies.splice(i,1)
                i--
            }
        }
        if(frame % enemyInterval === 0 && score < winningScore){
            let verticalPosition = Math.floor(Math.random() * 5 + 1) * cellSize + cellGap;
            enemies.push(new Enemy(verticalPosition))
            enemiesPositions.push(verticalPosition)
            if(enemyInterval > 120) enemyInterval -= 50
        }
    }

    const handleGameStatus = () => {
        if(!context) return;
        context.fillStyle = 'gold'
        context.font = '30px Amstrad CPC464 Regular'
        context.fillText('Score: ' + score, 20, 40)
        context.fillText('Recursos: ' + numberOfResources, 20, 80)
        if(gameOver){
            context.fillStyle = 'black'
            context.font = '60px Amstrad CPC464 Regular'
            context.fillText('GAME OVER', 135, 330)
        }
        if(score >= winningScore && enemies.length === 0){
            context.fillStyle = 'black'
            context.font = '45px Amstrad CPC464 Regular'
            context.fillText("NIVEL COMPLETADO", 130, 300);
            context.font = '30px Amstrad CPC464 Regular'
            context.fillText("Puntuacion: " + score, 134, 340);
        }
    }

    const handleProjectiles = () => {
        for(let i=0;i<projectiles.length;i++){
            projectiles[i].update()
            projectiles[i].draw()
            for(let j=0;j<enemies.length;j++){
                if(enemies[j] && projectiles[i] && collision(projectiles[i], enemies[j])){
                    enemies[j].health -= projectiles[i].power
                    projectiles.splice(i,1)
                    i--
                }
            }
            if(projectiles[i] && projectiles[i].x > canvasWidth){
                projectiles.splice(i,1)
                i--
            }
        }
    }

    const handleResources = () => {
        if(frame % 500 === 0 && score < winningScore){
            resources.push(new Resource())
        }
        for(let i = 0;i<resources.length;i++){
            resources[i].draw()
            if(resources[i] && mouse.x && mouse.y && collision(resources[i], mouse)){
                numberOfResources += resources[i].amount;
                floatingMessages.push(new FloatingMessage('+' + resources[i].amount, resources[i].x, resources[i].y, 20, 'black'))
                floatingMessages.push(new FloatingMessage('+' + resources[i].amount, 250, 50, 30, 'gold'))
                resources.splice(i,1);
                i--;
            }
        }
    }

    const handleFloatingMessages = () => {
        for(let i=0;i<floatingMessages.length;i++){
            floatingMessages[i].update()
            floatingMessages[i].draw()
            if(floatingMessages[i].lifeSpam >= 50){
                floatingMessages.splice(i,1)
                i--
            }
        }
    }

    const collision = (first, second) => {
        if(!(first.x > second.x + second.width ||
             first.x + first.width < second.x ||
             first.y > second.y + second.height ||
             first.y + first.height < second.y)
        ){
            return true;
        }
        return false;
    }
    // Proyectiles
    class Projectiles {
        constructor(x,y){
            this.x = x
            this.y = y
            this.width = 10
            this.height = 10
            this.power = 20
            this.speed = 5
        }
        update(){
            this.x += this.speed
        }
        draw(){
            context.fillStyle = 'black'
            context.beginPath();
            context.arc(this.x, this.y, this.width, 0, Math.PI * 2)
            context.fill()
        }
    }

    // Defensores
    class Defender {
        constructor(x, y){
            this.x = x
            this.y = y
            this.width = cellSize - cellGap * 2
            this.height = cellSize - cellGap * 2
            this.shooting = false
            this.health = 100
            this.projectiles = []
            this.timer = 0
        }
        draw(){
            context.fillStyle = 'blue'
            context.fillRect(this.x, this.y, this.width, this.height)
            context.fillStyle = 'gold'
            context.font = '20px Amstrad CPC464 Regular';
            context.fillText(Math.floor(this.health), this.x + 15, this.y + 25);
        }
        update(){
            if(this.shooting){
                this.timer++;
                if(this.timer % 100 === 0){
                    projectiles.push(new Projectiles(this.x+70, this.y+70))
                }
            }else{
                this.timer = 0
            }
            
        }
    }

    // Mensajes flotantes
    const floatingMessages = []
    class FloatingMessage {
        constructor(value, x, y, size, color){
            this.value = value
            this.x = x
            this.y = y
            this.size = size
            this.lifeSpam = 0
            this.color = color
            this.opacity = 1
        }
        update(){
            this.y -= 0.3
            this.lifeSpam += 1
            if(this.opacity > 0.05) this.opacity -= 0.05
        }
        draw(){
            context.globalAlpha = this.opacity
            context.fillStyle = this.color
            context.font = this.size + 'px Amstrad CPC464 Regular'
            context.fillText(this.value, this.x, this.y)
            context.globalAlpha = 1
        }
    }

    // Enemigos
    class Enemy{
        constructor(verticalPosition){
            this.x = canvasWidth;
            this.y = verticalPosition
            this.width = cellSize - cellGap * 2
            this.height= cellSize - cellGap * 2
            this.speed = Math.random() * 0.2 + 0.4
            this.movement = this.speed
            this.health = 100;
            this.maxHealth = this.health
        }
        update(){
            this.x -= this.movement
        }
        draw(){
            context.fillStyle = 'red'
            context.fillRect(this.x, this.y, this.width, this.height)
            context.fillStyle = 'black'
            context.font = '20px Amstrad CPC464 Regular';
            context.fillText(Math.floor(this.health), this.x + 15, this.y + 25);
        }
    }

    // Recursos
    const amounts = [20,30,40]
    class Resource {
        constructor(){
            this.x = Math.random() * (canvasWidth - cellSize)
            this.y = (Math.floor(Math.random() * 5) + 1) * cellSize + 25
            this.width = cellSize * 0.6
            this.height = cellSize * 0.6
            this.amount = amounts[Math.floor(Math.random() * amounts.length)];
        }
        draw(){
            context.fillStyle = 'yellow'
            context.fillRect(this.x, this.y, this.width, this.height)
            context.fillStyle = 'black'
            context.font = '20px Amstrad CPC464 Regular';
            context.fillText(this.amount, this.x + 15, this.y + 25)
        }
    }
    // Utilidades

    const draw = (ctx, frameCount, width, height) => {
        setCanvasWidth(width)
        setCanvasHeight(height)
        setContext(ctx)
        ctx.clearRect(0,0,width, height)
        ctx.fillStyle = 'blue'
        ctx.fillRect(0,0, controlsBar.width, controlsBar.height)

        if(context){
            handleGameGrid()
            handleResources()
            handleDefenders()
            handleEnemies()
            handleProjectiles()
            handleGameStatus()
            handleFloatingMessages()
        }
        
        if(!gameOver)
            frame++;

        
    }
    return (
        <>
            <DivBoard>
                <Canvas draw={draw} width={'900px'} height={'600px'} background={'#fff'} onMouseMove={mouseMove} onMouseLeave={mouseLeave} onClick={mouseClick}/>
            </DivBoard>
        </>
    )
}

export default TowerDefense
