import React, {useState, useRef, useContext} from 'react'
import {StoreContext} from './index'
import * as RiIcons from 'react-icons/ri'
import FormAddPlaylist from './FormAddPlaylist'
import ModalAddPlaylist from './ModalAddPlaylist'
import { DivSideBar, LiNewPlaylist, LiPlaylist } from './MusicElements'
import Toast from './Toast'

const SideBar = ({children}) => {
    const [sidebarState, setSidebarState] = useState({
        modal: false,
        toast: ''
    })

    const { state, dispatch } = useContext(StoreContext)
    const playlistRef = useRef(null)
    const playlists = Object.keys(state.playlists)

    const clickPlayList = (list) => {
        dispatch({type:'SET_PLAYLIST', playlist: list})
    }

    const addPlaylist = e => {
        e.preventDefault();
        const list = playlistRef.current.value

        dispatch({type: 'ADD_PLAYLIST', playlist: list})
        setSidebarState({
            ...sidebarState,
            modal: false,
            toast: 'Playlist creada'
        })
    }

    return (
        <DivSideBar>
            <RiIcons.RiMusicLine size={60}/>
            <ul>
                <LiPlaylist inactive={true}>Library</LiPlaylist>
                {
                    playlists.map(list => <LiPlaylist key={list}
                         active={state.currentPlaylist === list}
                         onClick={()=>clickPlayList(list)}>{list}</LiPlaylist>)   
                }
                <LiNewPlaylist onClick={() => { setSidebarState({...sidebarState, modal: true})}}>
                    <RiIcons.RiPlayListAddFill/>
                    <span>New PL</span>
                </LiNewPlaylist>
            </ul>
            {children}

            <ModalAddPlaylist show={sidebarState.modal} close={() => { setSidebarState({...sidebarState, modal: false})}}>
                <FormAddPlaylist handleSubmit = {addPlaylist} refInput={playlistRef} />
            </ModalAddPlaylist>
            <Toast toast={sidebarState.toast} close={() => { setSidebarState({...sidebarState, toast: ''})}}/>
        </DivSideBar>
    )
}

export default SideBar
