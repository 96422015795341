const ScoreBoard = ({score, resetGame}) => {
	return(
		<div className="details-box">
                <div className="resetButton" onClick={resetGame}>Reset</div>
                <div className="score-box">
                    <div className="score-header">SCORE</div>
                    <div>{score}</div>
                </div>
        </div>
	)
}

export default ScoreBoard