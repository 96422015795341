import styled from 'styled-components'

export const DivCanvas = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${props => props.width || '900px'};
    height: ${props => props.height || '600px'};
`

export const EmuTitle = styled.h1`
    margin: auto;
    text-align: center;
`

export const EmuMain = styled.div`
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    display: flex;
`

export const EmuSelectGame = styled.select`
    -moz-appearance: none;
  -webkit-appearance: none;
  border: 3px solid #221e1f;
  color: #221e1f;
  background: transparent !important;
  padding: 16px;
  font-size: 16px;
  display: block;
  border-radius: 0;
  outline: none;
  line-height: 2;

`