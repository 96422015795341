import React from 'react'
import { Link } from 'react-router-dom'

function Chat() {
    return (
        <>
        </>
    )
}

export default Chat
