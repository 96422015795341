import React, {useEffect, useState} from 'react'
import Canvas from '../Canvas'

const Lab6 = () => {
    const [canvasWidth, setCanvasWidth] = useState(1670)
    const [canvasHeight, setCanvasHeight] = useState(889)

    let eyes = []
    let theta

    let mouse = {
        x: undefined,
        y: undefined
    };

    window.addEventListener('mousemove',function(event){
        mouse.x = event.layerX
        mouse.y = event.layerY
    })

    window.addEventListener('mouseout', function(){
        mouse.x = undefined
        mouse.y = undefined
    })

    useEffect(() => {
        eyes = []
        let overlapping = false
        let numberOfEyes = 300
        let protection = 10000
        let counter = 0

        while(eyes.length < numberOfEyes && counter < protection){
            let eye = {
                x: Math.random() * canvasWidth,
                y: Math.random() * canvasHeight,
                radius: Math.floor(Math.random() * 100) + 5
            }
            overlapping = false;
            for(let i =0;i<eyes.length;i++){
                let previousEye = eyes[i]
                let dx = eye.x - previousEye.x
                let dy = eye.y - previousEye.y
                let distance = Math.sqrt(dx*dx + dy*dy);
                //if(distance > (eye.radius + previousEye.radius)*3){
                if(distance < (eye.radius + previousEye.radius)){
                    overlapping = true
                    break;
                }
            }
            if(!overlapping){
                eyes.push(new Eye(eye.x, eye.y, eye.radius))
            }
            counter++;
        }
    }, [canvasWidth, canvasHeight])

    class Eye{
        constructor(x, y, radius){
            this.x = x
            this.y = y
            this.radius = radius
        }
        draw = function(ctx){
            // Dibuajmos el ojo
            ctx.beginPath()
            ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2, true)
            ctx.fillStyle = "red"
            ctx.fill()
            ctx.closePath()

            // Obtenemos el angulo con el raton
            let dx = mouse.x - this.x
            let dy = mouse.y - this.y
            theta = Math.atan2(dx, dy)

            // dibujamos el iris
            let iris_x = this.x + Math.sin(theta) * this.radius / 10;
            let iris_y = this.y + Math.cos(theta) * this.radius / 10;
            let irisRadius = this.radius / 1.2
            ctx.beginPath()
            ctx.arc(iris_x,iris_y, irisRadius, 0, Math.PI * 2, true)
            ctx.fillStyle = "white"
            ctx.fill()
            ctx.closePath()

            // Dibujamos la pupila
            let pupil_x = this.x + Math.sin(theta) * this.radius / 1.9;
            let pupil_y = this.y + Math.cos(theta) * this.radius / 1.9;
            let pupilRadius = this.radius / 2.5
            ctx.beginPath()
            ctx.arc(pupil_x,pupil_y, pupilRadius, 0, Math.PI * 2, true)
            ctx.fillStyle = "black"
            ctx.fill()
            ctx.closePath()

            // Dibujamos el reflejo de la pupila
            ctx.beginPath()
            ctx.arc(pupil_x - pupilRadius/3,pupil_y - pupilRadius/3, pupilRadius/2, 0, Math.PI * 2, true)
            ctx.fillStyle = "rgba(255,255,255,.1)"
            ctx.fill()
            ctx.closePath()

            // Dibujamos el raton
            ctx.beginPath()
            ctx.arc(mouse.x, mouse.y, 25, 0, Math.PI * 2, true)
            ctx.fillStyle = "gold"
            ctx.fill()
            ctx.closePath()

        }
        // Comprobamos la posicion de cada particula, y la 
        update = function(ctx){
            this.position += this.step
            this.draw(ctx)
        }
    }

    

    const draw = (ctx, frameCount) => {
        setCanvasWidth(ctx.canvas.width)
        setCanvasHeight(ctx.canvas.height)
        ctx.fillStyle = 'rgba(0,0,0,0.25)'
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
        for(let i=0;i<eyes.length;i++){
            eyes[i].draw(ctx)
        }
    }

    return (
        <Canvas draw={draw} background='#000' />
    )
}

export default Lab6
