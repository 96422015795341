import React, {useState, useEffect} from 'react'
import { DivBoard } from '../GamesElements'
import ScoreBoard from './ScoreBoard'
import blueGem from './images/gem_blue.gif'
import greenGem from './images/gem_green.gif'
import orangeGem from './images/gem_orange.gif'
import purpleGem from './images/gem_purple.gif'
import redGem from './images/gem_red.png'
import yellowGem from './images/gem_yellow.gif'
import blank from './images/blank.gif'
import './main.css'

const width = 8
const candyColors = [
    blueGem,
    greenGem,
    orangeGem,
    purpleGem,
    redGem,
    yellowGem
]


const Match3 = () => {
    const [currentColorArrangement, setCurrentColorArrangement] = useState([])
    const [squareBeingDragged, setSquareBeingDragged] = useState(null)
    const [squareBeingReplaced, setSquareBeingReplaced] = useState(null)
    const [scoreDisplay, setScoreDisplay] = useState(0)

    const checkForColumnOfThree = () => {
        for(let i=0;i<48;i++){
            const columnOfThree = [i, i+width,i+width * 2]
            const decidedColor = currentColorArrangement[i]
            const isBlank = currentColorArrangement[i] === blank

            if(columnOfThree.every(square => currentColorArrangement[square] === decidedColor) && !isBlank){
                setScoreDisplay((score) => score + 3)
                columnOfThree.forEach(square => currentColorArrangement[square] = blank)
                return true
            }
        }
    }

    const checkForColumnOfFour = () => {
        for(let i=0;i<40;i++){
            const columnOfFour = [i, i+width,i+width * 2, i+width*3]
            const decidedColor = currentColorArrangement[i]
            const isBlank = currentColorArrangement[i] === blank

            if(columnOfFour.every(square => currentColorArrangement[square] === decidedColor) && !isBlank){
                setScoreDisplay((score) => score + 4)
                columnOfFour.forEach(square => currentColorArrangement[square] = blank)
                return true
            }
        }
    }

    const checkForRowOfThree = () => {
        for(let i=0;i<64;i++){
            const rowOfThree = [i, i+1, i+2]
            const decidedColor = currentColorArrangement[i]
            const notValid = [6,7,14,15,22,23,30,31,38,39,46,47,54,55,63,64]
            const isBlank = currentColorArrangement[i] === blank

            if(notValid.includes(i)) continue

            if(rowOfThree.every(square => currentColorArrangement[square] === decidedColor) && !isBlank){
                setScoreDisplay((score) => score + 3)
                rowOfThree.forEach(square => currentColorArrangement[square] = blank)
                return true
            }
        }
    }

    const checkForRowOfFour = () => {
        for(let i=0;i<64;i++){
            const rowOfFour = [i, i+1, i+2]
            const decidedColor = currentColorArrangement[i]
            const notValid = [5,6,7,13,14,15,21,22,23,29,30,31,37,38,39,45,46,47,53,54,55,61,62,63]
            const isBlank = currentColorArrangement[i] === blank

            if(notValid.includes(i)) continue

            if(rowOfFour.every(square => currentColorArrangement[square] === decidedColor) && !isBlank){
                setScoreDisplay((score) => score + 4)
                rowOfFour.forEach(square => currentColorArrangement[square] = blank)
                return true
            }
        }
    }

    const moveIntoSquareBelow = () => {
        for(let i=0;i<64 - width;i++){
            const firstRow = [0,1,2,3,4,5,6,7]
            const isFirstRow = firstRow.includes(i)

            if(isFirstRow && currentColorArrangement[i] === blank){
                let randomNumber = Math.floor(Math.random() * candyColors.length)
                currentColorArrangement[i] = candyColors[randomNumber]
            }

            if((currentColorArrangement[i + width] === blank)){
                currentColorArrangement[i + width] = currentColorArrangement[i]
                currentColorArrangement[i] = blank
            }
        }
    }

    const dragStart = (e) => {
        console.log(e.target)
        setSquareBeingDragged(e.target)
    }

    const dragDrop = (e) => {
        console.log(e.target)
        setSquareBeingReplaced(e.target)
    }

    const dragEnd = (e) => {
        const squareBeingDraggedId = parseInt(squareBeingDragged.getAttribute('data-id'))
        const squareBeingReplacedId = parseInt(squareBeingReplaced.getAttribute('data-id'))

        currentColorArrangement[squareBeingDraggedId] = squareBeingReplaced.getAttribute('src')
        currentColorArrangement[squareBeingReplacedId] = squareBeingDragged.getAttribute('src')

        const validMoves = [
            squareBeingDraggedId - 1,
            squareBeingDraggedId - width,
            squareBeingDraggedId + 1,
            squareBeingDraggedId + width
        ]

        const validMove = validMoves.includes(squareBeingReplacedId)

        const isAColumnOfFour = checkForColumnOfFour()
        const isARowOfFour = checkForRowOfFour()
        const isAColumnOfThree = checkForColumnOfThree()        
        const isARowOfThree = checkForRowOfThree()

        if(squareBeingReplacedId &&
            validMove &&
            (isARowOfThree || isAColumnOfThree || isARowOfFour || isAColumnOfFour)){
            setSquareBeingDragged(null)
            setSquareBeingReplaced(null)
        }else{
            currentColorArrangement[squareBeingReplacedId] = squareBeingReplaced.getAttribute('src')
            currentColorArrangement[squareBeingDraggedId] = squareBeingDragged.getAttribute('src')
            setCurrentColorArrangement([...currentColorArrangement])
        }

    }

    const createBoard = () => {
        const randomColorArrangement = []
        for(let i=0;i<width*width;i++){
            const randomNumber = Math.floor(Math.random() * candyColors.length)
            const randomColor = candyColors[randomNumber]
            randomColorArrangement.push(randomColor)
        }
        setCurrentColorArrangement(randomColorArrangement)
    }

    useEffect (() => {
        createBoard()
    }, [])

    useEffect(() => {
        const timer = setInterval(() => {
            checkForColumnOfFour()
            checkForRowOfFour()
            checkForColumnOfThree()            
            checkForRowOfThree()
            moveIntoSquareBelow()
            setCurrentColorArrangement([...currentColorArrangement])
        },100)
        return () => clearInterval(timer)
    }, [checkForColumnOfFour, checkForColumnOfThree, checkForRowOfFour, checkForRowOfThree, moveIntoSquareBelow, currentColorArrangement])
    

    return (
        <DivBoard>
            <ScoreBoard score={scoreDisplay}></ScoreBoard>
            <div className="game">
                {currentColorArrangement.map((candyColor, index) => (
                    <img
                        key={index}
                        src={candyColor}
                        data-id={index}
                        draggable={true}
                        onDragStart={dragStart}
                        onDragOver={(e) => e.preventDefault()}
                        onDragEnter={(e) => e.preventDefault()}
                        onDragLeave={(e) => e.preventDefault()}
                        onDrop = { dragDrop }
                        onDragEnd = {dragEnd}
                    />
                ))}
            </div>            
        </DivBoard>
    )
}

export default Match3
