import styled from 'styled-components'
import { NavLink as Link } from 'react-router-dom'
import { FaBars } from 'react-icons/fa'

export const Nav = styled.nav`
    background-image: linear-gradient(to bottom right, #221e1f,#e53240);
    height: 80px;
    display:flex;
    justify-content: space-between;
    padding: 0.5rem;
    z-index: 10;
`

export const NavLink = styled(Link)`
    color: #fcfcfc;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;

    &.active {
        color: #e53240;
    }
`

export const Bars = styled(FaBars)`
    display: none;
    color: #fff;

    /*@media screen and (max-width: 768px){
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate()(-100%, 75%);
        font-size: 1.8rem;
        cursor: pointer;
    }*/
`

export const NavMenu = styled.div`
    display: flex;
    align-items: center;
    /*margin-right: --24px;*/

    margin-right: 24px;
    @media screen and (max-width: 768px){
        display: none;
    }
`
export const NavBtn = styled.nav`
    display: flex;
    align-items: center;
    margin-right: 24px;
    @media screen and (max-width: 768px){
        display: none;
    }
`

export const NavBtnLink = styled(Link)`
    border-radius: 250px;
    background: #fcfcfc;
    padding: 10px 22px;
    display: flex;
    align-items: center;
    color: #e53240;
    border: none;
    outline: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    margin-left: 24px;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #e53240;
        color: blue;
    }
`

