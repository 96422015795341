import styled from 'styled-components'



export const DivMusic = styled.div`
    height: calc(100vh - 80px);
    width: 100%;
    display: flex;
    /*position: relative;*/
    color: white;
`

export const DivSideBar = styled.div`
    width: 200px;
    height: 100%;
    background: #000;
    padding-top: 20px;
`

export const DivContent = styled.div`
    flex-grow: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    background: red;
    text-transform: capitalize;
`

export const DivPlayBar = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 75px;
    background: #282828;
    z-index: 99;
    padding: 20px;
`

export const DivTopBar = styled.div`
    position: relative;
    top: 0;
    height: 50px;
    background: #070707;
    left: 200px;
    width: calc(100% - 200px);
    padding: 20px;
`

export const LiPlaylist = styled.li`
    padding-left: ${props => props.active ? '18px' : '20px'};
    border-left: ${props => props.active ? '2px solid white' : ''};
    text-transform: ${props => props.inactive ? 'uppercase':'capitalize'};
    margin-bottom: 10px;
    cursor: ${props => props.inactive ? 'unset':'pointer'};
    color: ${props => props.inactive ? '#999':'#fff'};
    font-weight: ${props => props.inactive ? 'normal':'bold'};
    text-decoration: none;
`

export const LiNewPlaylist = styled(LiPlaylist)`
    position: absolute;
    bottom: 80px;
    svg {
        margin-right: 5px;
        transform: translateY()(1px);
    }

    span{
        color: #999;
        font-size: 14;
    }
`

export const DivModalAddPlayList = styled.div`
    height: 100vh;
    width: 100vw;
    background: rgba(0,0,0,0.75);
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .modal-content {
        position: relative;
        width: 400px;
        background: #211f27;
        border-radius: 4px;
        padding: 25px;
    }

    svg {
        position: absolute;
        right: 15px;
        top: 15px;
        cursor: pointer;
    }
`

export const StyledFormPlaylist = styled.form`
    button {
        background-color: #2bcc6c;
        color: white;
        padding: 12.5px 30px;
        border-radius: 5px;
        text-transform: uppercase;
        font-size: 11px;
        cursor: pointer;
    }

    .title{
        margin: 0;
        margin-bottom: 35px;
    }

    input {
        margin-bottom: 20px;
        height: 40px;
        padding-left: 8px;
        width: 100%;
        font-size: 10px;
        color: black;
    }

    .content-wrap {
        margin: 0px auto;
        max-width: 250px;
        text-align: center;
    }
`

export const DivToast = styled.div`
    animation: toast 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    position: absolute;
    top: 200px;
    left: calc(50% - 175px);
    background: white;
    color: black;
    width: 350px;
    padding: 25px;
    text-align: center;

    @keyframes toast{
        0%{
            transform: translateY(0px);
        }
        100%{
            transform: translateY(75px);
        }
    }
`

export const DivPlayListTitle = styled.div`
    font-size: 20px;
`